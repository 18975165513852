import './CentreHistorical.scss';

import image1_centre_historical from '../../assets/images/jpg/riga/centre-historical/1.jpg';
import image2_centre_historical from '../../assets/images/jpg/riga/centre-historical/2.jpg';
import image3_centre_historical from '../../assets/images/jpg/riga/centre-historical/3.jpg';
import image4_centre_historical from '../../assets/images/jpg/riga/centre-historical/4.jpg';
import image5_centre_historical from '../../assets/images/jpg/riga/centre-historical/5.jpg';
import image6_centre_historical from '../../assets/images/jpg/riga/centre-historical/6.jpg';

const CentreHistorical = () => {
    return (
        <div className='section__child centre-historical'>
            <div className='box-image image-1'>
                <img src={image1_centre_historical} />
                <span className='lv_lv'>Svētā Jāņa baznīca</span>
                <span className='it_it'>Chiesa di San Giovanni</span>
            </div>
            <h3>
                <span className='lv_lv'>Vēsturiskais centrs</span>
                <span className='it_it'>Il centro storico</span>
            </h3>
            <div>
                <div className='box-image image-2'>
                    <img src={image2_centre_historical} />
                    <span className='lv_lv'>Svētā Pētera baznīca</span>
                    <span className='it_it'>Chiesa di San Pietro</span>
                </div>
                <p>
                    Il giro alla scoperta di Riga comincia obbligatoriamente dal suo centro storico. Il vecchio nucleo urbano del XIII secolo è piccolo e compatto, molto pittoresco grazie alle tante chiese, case delle corporazioni e abitazioni borghesi in stile gotico baltico</p>

            </div>
            <div>
                <div className='box-image image-3'>
                    <img src={image3_centre_historical} />
                    <span className='lv_lv'>Brēmenes pilsētas mūziķu skulptūra</span>
                    <span className='it_it'>La statua dei Musicanti di Brema</span>
                </div>
                <p>Imperdibili sono <b>la Chiesa di San Giovanni</b>, riconoscibile dal vistoso frontone a gradoni sulla facciata nord e, a pochi passi, <b>la Chiesa di San Pietro</b>, la cui torre di 72 metri domina il profilo della città vecchia. In mezzo ai due edifici di culto, la <b>statua dei Musicanti di Brema</b>, un regalo della città tedesca nel 1990 vista la storia comune delle due città. Da una piazza all’altra a un cortile all’altro: da visitare <b>il Cortile di San Giovanni</b> e il <b>Cortile del convento</b>.
                </p>
            </div>
            <div>
                <div className='box-image image-4'>
                    <img src={image4_centre_historical} />
                    <span className='lv_lv'>Melngalvju nams</span>
                    <span className='it_it'>La Casa delle Teste Nere</span>
                </div>
                <div className='box-image image-4'>
                    <img src={image6_centre_historical} />
                    <span className='lv_lv'>Melngalvju nams</span>
                    <span className='it_it'>Tre Fratelli</span>
                </div>
                <p>
                    Una brevissima passeggiata conduce a quello che forse è l’edificio più rappresentativo di Riga, <b>la Casa delle Teste Nere</b>, di fronte al Municipio. Un altro complesso molto famoso di Riga è quello dei <b>Tre Fratelli</b>, tre edifici gotici molto pittoreschi: si trovano nei pressi della <b>Chiesa di San Giacomo</b> e del Parlamento lettone da cui è un attimo arrivare al <b>Castello</b> e all’Arsenale.
                </p>
            </div>
            <div>
                <div className='box-image image-5'>
                    <img src={image5_centre_historical} />
                    <span className='lv_lv'>Katedrāle</span>
                    <span className='it_it'>Il Duomo</span>
                </div>
                <p>
                    <b>Il Duomo con il chiostro</b> vanno assolutamente visitati all’interno, per poi terminare il giro sulla piazza che ospita la sede della Grande e della Piccola Gilda, senza dimenticare di alzare gli occhi in cima alla Casa dei Gatti e cercare la storia di questo palazzo e delle statue dei due felini poste in cima.
                </p>
            </div>
        </div>

    )
}

export default CentreHistorical;