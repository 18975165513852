import './Moscovita.scss';

import image1_moscovita from '../../assets/images/jpg/riga/moscovita/image1.jpg';
import image2_moscovita from '../../assets/images/jpg/riga/moscovita/image2.jpg';

const Moscovita = () => {
    return (
        <div className='section__child moscovita'>
            <h3>
                <span className='it_it'>Quartiere Moscovita</span>
            </h3>
            <div>
                <div className='box-image image-2'>
                    <img src={image1_moscovita} />
                </div>
                <p>
                    Uno delle zone più particolari della capitale lettone è il quartiere moscovita, in passato abitato quasi soltanto da russi ed ebrei. La strada principale del quartiere è <i>Maskavas iela</i>.<br /><br />
                    Vale la pena fare un giro da queste parti anche per vedere il vistoso palazzo del 1958 in tipico stile staliniano che ospita l’<b>Accademia delle Scienze</b>, nonostante inizialmente dovesse ospitare le case dei contadini dei kolchotz. È possibile salire sulla terrazza panoramica a 65 metri di altezza da cui si può godere una bella vista a 360 gradi sulla città e sul fiume Daugava: si prende l’ascensore fino al 15° piano e poi si percorrono un paio di rampe di scale a piedi.

                </p>
                <div className='box-image image-3'>
                    <img src={image2_moscovita} />
                </div>
                <p>
                    Il quartiere moscovita inizia dove finisce il grande <b>Mercato centrale</b>, subito dietro la stazione centrale ed è proprio da qui che conviene cominciare l’itinerario se, come probabile, si proviene dal centro. I cinque padiglioni che ospitano il mercato risalgono agli anni Venti ma ci sono bancarelle anche all’esterno. I padiglioni sono tematici e si possono trovare carne, pesce, frutta, verdura, souvenir e abbigliamento di vario genere. <br /><br />
                    Il mercato centrale di Riga è il più grande mercato e bazar d'Europa a Riga, in Lettonia. È una delle strutture più importanti del 20 ° secolo in Lettonia ed è stata inclusa nella lista dei siti patrimonio mondiale dell'UNESCO insieme alla Vecchia Riga nel 1998.

                </p>
            </div>
        </div>

    )
}

export default Moscovita;