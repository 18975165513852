import './ArtNoveau.scss';

import image1_art_noveau from '../../assets/images/jpg/riga/art-noveau/image1.jpg';

const ArtNoveau = () => {
    return (
        <div className='section__child art-noveau'>
            <h3>
                <span className='it_it'>Art Noveau</span>
            </h3>
            <div>
                <div className='box-image image-2'>
                    <img src={image1_art_noveau} />
                </div>
                <p>
                    Sfingi, draghi, leoni, maschere umane e di demoni spuntano dalle facciate dei palazzi di un piccolo quartiere a nord-est della città, dove in poche centinaia di metri si può osservare un’estrema varietà di quest’epoca architettonica. Secondo alcuni Riga meriterebbe il titolo di capitale europea dell’Art Noveau. Nonostante sia molto meno famosa di Vienna o Bruxelles, in realtà la città custodisce un patrimonio architettonico di notevole qualità, varietà e originalità. Di certo, il tour dei palazzi Art Noveau è una delle cose imperdibili da vedere a Riga.

                </p>
                <p>
                    Nella città nuova, non troppo distante dal centro storico, c’è addirittura un’intera strada dove i palazzi art noveau si susseguono senza soluzione di continuità (uni di essi ospita anche il museo dedicato a questo stile architettonico, un altro il Centro di Arte Contemporanea): è <b>Alberta iela</b>, in cui moltissimi palazzi furono realizzati da Mikhail Eisenstein, il più famoso architetto di Riga che ha progettato molto edifici in città.

                </p>
                <p>
                    Se avete poco tempo, cercate di percorrere perlomeno <b>Elizabetes iela</b> e <b>Alberta iela</b>. Se avete un po’ di tempo continuate la passeggiata osservando gli edifici in <b>Strelnieku iela</b>, <b>Vilandes iela</b>, <b>Antonijas iela</b> e <b>Jura Alunana iela</b>. Da queste parti ci sono anche il Museo Nazionale di Belle Arti e il Museo di Storia della Medicina.
                </p>
            </div>
        </div>

    )
}

export default ArtNoveau;