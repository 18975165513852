import { useState } from 'react';
import ArtNoveau from './ArtNoveau';
import Canal from './Canal';
import CentreHistorical from './CentreHistorical';
import MenuRiga from './MenuRiga';
import Moscovita from './Moscovita';
import './Riga.scss';


const Riga = () => {
    const [isActive, setIsActive] = useState('1')
    return (
        <section id="riga">
            <div className='title-seciont-box text-center'>
                <h2>
                    <span className="lv_lv">Rīga</span>
                    <span className="it_it">Riga</span>
                </h2>
            </div>
            <MenuRiga setIsActive={setIsActive} isActive={isActive} />
            {isActive === "1" &&
                <CentreHistorical />
            }
            {isActive === "2" &&
                <Canal />
            }
            {isActive === "3" &&
                <ArtNoveau />
            }
            {isActive === "4" &&
                <Moscovita />
            }
        </section>
    )
}

export default Riga;