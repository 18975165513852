import { Fragment } from "react"
import './MenuFood.scss'

const MenuFood = () => {

    const dataLv = [
        { title: 'Karstie ēdieni', par: ["Lasagna", "Grilēts Laša steiks ar rozā pipariem", "Cūkgaļas kotletes mājas gaumē"] },
        { title: 'Piedevas', par: ["Krāsnī cepti kartupeļi ar dillītēm", "Krāsnī ceptu dārzeņu maisījums", "Siera-krējuma mērce", "Aukstā krējuma mērce ar dillēm"] },
        { title: 'Zivju uzkodas', par: ["Kartupeļu rosts ar mazsālīta laša rozīti, citronu un olīvām", "Kantainais rudzu grauzdiņš ar sinepju krēmu, olas šķēlīti un siļķes fileju"] },
        { title: 'Gaļas uzkodas', par: ["Strūdele ar pikanto maltās gaļas pildījumu", "Biešu groziņš ar kūpinātas vistas krēmu ar ananāsiem un svaigu sieru", "Spinātu pankūku sainīši ar vistas masas pildījumu apsieti ar maurlociņu", "Spāņu vītinātais šķiņķis (Jamon iberico)"] },
        { title: 'Veģetārās uzkodas', par: ["Batātes medaljoni ar avokado, turku zirņu humusu, dīgstiem un kaltētiem tomātiem", "Veganbumbiņas ar marinēta gurķa buriņu", "Dārzeņu izlase ar zaļumu mērcīti"] },
        { title: 'Salāti', par: ["Siļķe kažokā", "Svētku rasols ar kūpinātu šķiņķi", "Kvinojas salāti ar rukolu, graudaugiem, vīnogām, apelsīnu filejām un citrusaugu mērci"] },
        { title: 'Uzkodas', par: ["Izmeklētu sieru izlase ar vīnogām un grisīnī", "Rudzu maize, ciabata, franču bagete un zaļumu sviests", "Vistas gaļas – siera krokete melnajā sezamā ar grilētas paprikas buriņu"] },
        { title: 'Deserts', par: ["Sezonas augļu izlase", "Kāzu torte"] },
    ]

    const dataIt = [
        { title: 'Piatti caldi', par: ["Lasagna", "Trancio di salmone alla griglia con pepe rosa", "Polpette di maiale"] },
        { title: 'Contorni', par: ["Patate al forno con aneto", "Verdure miste al forno", "Salsa al formaggio", "Salsa fredda alla panna con aneto"] },
        { title: 'Antipasto di pesce', par: ["Frittelle di patate con salmone salato, limone e olive", "Crostino di segale con crema di senape, fetta d'uovo e filetto d'aringa"] },
        { title: 'Antipasto di carne', par: ["Strudel con ripieno di carne macinata piccante", "Cestino di barbabietola con crema di pollo affumicato con ananas e formaggio fresco", "Crepes agli spinaci con ripieno di pollo avvolti da erba cipollina", "Jamon iberico"] },
        { title: 'Spuntini vegetariani', par: ['Medaglioni di patate dolci con avocado, hummus di ceci, germogli e pomodori secchi', 'Polpette vegane con vela di cetrioli sottaceto', 'Selezione di verdure fresche con salsa alle erbe'] },
        { title: 'Insalata', par: ["Aringhe in pelliccia", "Insalata russa con prosciutto affumicato", "Insalata di quinoa con rucola, cereali, uva, filetti d'arancia e salsa agli agrumi"] },
        { title: 'Stuzzichini', par: ["Selezione di formaggi con uva e grissini", "Pane di segale, ciabatta, baguette francese e burro alle erbe", "Crocchetta di pollo e formaggio al sesamo nero con peperoni grigliati"] },
        { title: 'Dolce', par: ["Selezione di frutta", "La torta"] },
    ]
    return (
        <section id="menuFood">
            <div className='title-seciont-box text-center'>
                <h2>
                    <span className='lv_lv'>Kāzu ēdienkarte</span>
                    <span className='it_it'>Menu</span>
                </h2>
            </div>
            <div className="lv_lv menu-food">
                {dataLv.map((item, idx) =>
                    <Fragment key={idx}>
                        <h2>
                            {item.title}
                        </h2>
                        {item.par.map((par_item, idx_par) =>
                            <p key={idx_par}>
                                {par_item}
                            </p>
                        )}

                    </Fragment>
                )}
            </div>
            <div className="it_it menu-food">
                {dataIt.map((item, idx) =>
                    <Fragment key={idx}>
                        <h2>
                            {item.title}
                        </h2>
                        {item.par.map((par_item, idx_par) =>
                            <p key={idx_par}>
                                {par_item}
                            </p>
                        )}

                    </Fragment>
                )}
            </div>
        </section>
    )
}

export default MenuFood;