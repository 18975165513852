import "./Tips.scss";
import { Link } from "react-router-dom";
import icon_suitcase from '../../assets/images/png/icons/suitcase.png';
import icon_thermometer from '../../assets/images/png/icons/thermometer.png';
import icon_car from '../../assets/images/png/icons/car.png';
import icon_food from '../../assets/images/png/icons/food.png';
import { useEffect, useState } from "react";

import image1 from '../../assets/images/jpg/narvesen.jpg';
import Modal from "../Modal";
import { createPortal } from "react-dom";

const Tips = () => {

    const [isModal, setIsModal] = useState({ isOpen: false, image: '' })

    const portalElement = document.getElementById('modalBox')

    const [isActive, setisActive] = useState('')
    const onClickHandler = (e) => {
        let thermometer = document.getElementById('thermometer') !== null && document.getElementById('thermometer').offsetTop;
        let suitcase = document.getElementById('suitcase') !== null && document.getElementById('suitcase').offsetTop;
        let car = document.getElementById('car') !== null && document.getElementById('car').offsetTop;
        let food = document.getElementById('food') !== null && document.getElementById('food').offsetTop;
        setisActive(e.currentTarget.getAttribute('data-link'))
        if (e.currentTarget.getAttribute('data-link') === "thermometer") {
            window.scrollTo({
                top: thermometer,
                behavior: 'smooth'
            })
        } else if (e.currentTarget.getAttribute('data-link') === "suitcase") {
            window.scrollTo({
                top: suitcase,
                behavior: 'smooth'
            })
        }
        else if (e.currentTarget.getAttribute('data-link') === "car") {
            window.scrollTo({
                top: car,
                behavior: 'smooth'
            })
        }
        else if (e.currentTarget.getAttribute('data-link') === "food") {
            window.scrollTo({
                top: food,
                behavior: 'smooth'
            })
        }
    }


    const onZoomHandler = (photo) => {
        setIsModal({ isOpen: true, image: image1 })
    }


    useEffect(() => {
        window.addEventListener('scroll', (e) => {
            let active = '';
            let windowPageY = Math.ceil(window.pageYOffset);
            let thermometer = document.getElementById('thermometer') !== null && document.getElementById('thermometer').offsetTop;
            let suitcase = document.getElementById('suitcase') !== null && document.getElementById('suitcase').offsetTop;
            let car = document.getElementById('car') !== null && document.getElementById('car').offsetTop;
            let food = document.getElementById('food') !== null && document.getElementById('food').offsetTop;
            if (windowPageY >= thermometer && windowPageY < suitcase) {
                active = 'thermometer'
            } else if (windowPageY >= suitcase && windowPageY < car) {
                active = 'suitcase'
            } else if (windowPageY >= car && windowPageY < food) {
                active = 'car'
            } else if (windowPageY >= food) {
                active = 'food'
            } else {
                active = '';
            }
            setisActive(active)
        });

    }, [])


    return (
        <>
            {createPortal(<Modal isModal={isModal} onClose={() => setIsModal({ isOpen: false, image: '' })} />, portalElement)}
            <section id="tips" >
                <div className="title-seciont-box text-center">
                    <h2>
                        <span className="it_it">Consigli</span>
                    </h2>
                </div>
                <nav className="link-box">
                    <h3 className="d-none">Menu link</h3>
                    <ul>
                        <li>
                            <button data-link="thermometer" className={`${isActive === "thermometer" ? ' active' : ''}`} onClick={(e) => onClickHandler(e)}>
                                <img src={icon_thermometer} />
                            </button>
                        </li>
                        <li>
                            <button data-link="suitcase" className={`${isActive === "suitcase" ? ' active' : ''}`} onClick={(e) => onClickHandler(e)}>
                                <img src={icon_suitcase} />
                            </button>
                        </li>
                        <li>
                            <button data-link="car" className={`${isActive === "car" ? ' active' : ''}`} onClick={(e) => onClickHandler(e)}>
                                <img src={icon_car} />
                            </button>
                        </li>
                        <li>
                            <button data-link="food" className={`${isActive === "food" ? ' active' : ''}`} onClick={(e) => onClickHandler(e)}>
                                <img src={icon_food} />
                            </button>
                        </li>
                    </ul>
                </nav>

                <div className="tips-box">
                    <div id="thermometer" >
                        <div className="description">
                            <h3>Temperature in Lettonia</h3>
                            <p>
                                L'inverno in Lettonia è molto rigido. A febbraio la Temperatura può oscillare dai -20 °C (casi estremi) agli 0°C
                            </p>
                            <p>La neve generalmente è sempre presente con alternanza di piccoli e grandi accumuli al suolo</p>
                        </div>
                    </div>
                    <div id="suitcase">
                        <div className="description">
                            <h3>Cosa mettere in valigia</h3>
                            <p>Consigliamo di portare con voi:</p>
                            <ul>
                                <li>Calza e maglia termica</li>
                                <li>Cappelli invernali e guanti</li>
                                <li>Giubbotto invernale (potete prendere in considerazione quello di Decathlon{` `}
                                    <a target={"_blank"} href={`https://www.decathlon.it/p/giacca-montagna-uomo-sh500-ultra-warm/_/R-p-176633?mc=8582951&c=MARRONE`}>Link UOMO</a> {` - `}
                                    <a target={"_blank"} href={`https://www.decathlon.it/p/giacca-trekking-donna-sh500-ultra-warm-20-c/_/R-p-174714?mc=8641748`}>Link DONNA</a>
                                    )
                                </li>
                                <li>Scarponi o doposci invernali WATERPROOF (anche qui consigliamo quelli di Decathlon per rapporto qualità/prezzo)</li>
                                <li>Costumi e ciabatte per il bagno turco</li>
                            </ul>
                        </div>
                    </div>
                    <div id="car">
                        <div className="description">
                            <h3>Trasporti</h3>
                            <ul>
                                <li><b>TAXI</b>: consigliamo la compagnia BOLT per il prezzo davvero conveniente. Per usare questa compagnia dovete scaricare la loro APP da questo <a target={"_blank"} href="https://bolt.eu/it/">sito</a>.
                                    <br /> <b>Sconsigliamo di prendere taxi "fermi" all'areoporto ma anche in città in quanto pagherete molto di più</b>
                                </li>
                                <li><b>Tutti i trasporti publici</b>: consigliamo di comprare i biglietti nei tabacchi lettoni-Narvesen <button className='btn btn-outline-primary border-radius-circle only-icon' onClick={onZoomHandler}>i</button></li>
                                <li><b>Noleggio auto</b>:
                                    <ul>
                                        <li>
                                            <b>Per Riga</b>: consigliamo  l'autonoleggio <b>citybee</b> (<a target={"_blank"} href="https://citybee.lv/en/">sito</a>) che si trova all'areoporto internazionale di Riga. Prezzo medio di 0.18 €/min + 0.25 €/km (dipenderà dal tipo di macchina che sceglierete)
                                        </li>
                                        <li>
                                            <b>Per Riga e fuori Riga</b> consigliamo:

                                            <ul>
                                                <li>
                                                    autonoleggio <b>Rentalcars</b> (<a target={"_blank"} href="https://www.rentalcars.com/">sito</a>), in partership con la compagnia aerea AirBaltic
                                                </li>
                                                <li>
                                                    autonoleggio <b>RentMotors</b> (<a target={"_blank"} href="http://www.rentmotors.lv/">sito</a>)
                                                </li>
                                                <li>
                                                    autonoleggio <b>EcoRent</b> (<a target={"_blank"} href="https://ecorent.lv/en/">sito</a>)
                                                </li>
                                                <li>
                                                    autonoleggio <b>Europcar</b> (<a target={"_blank"} href="https://www.europcar.lv/en/">sito</a>)
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                    <br /><b>A differenza dell'Italia, in Lettonia ci sono regole più rigide. Per questo vi consigliamo</b>:
                                    <ul>
                                        <li>
                                            <b>rispettare sempre i limiti di velocità</b>
                                        </li>
                                        <li>
                                            <b>inserire sempre le frecce per svoltare nella direzione desiderata</b>
                                        </li>
                                        <li>
                                            <b>tutti i passeggeri devono avere la cintura di sicurezza</b>
                                        </li>
                                        <li>
                                            <b>prestare molta attenzione, fuori città, alla possibile presenza di animali come renne e cervi che potrebbero passare velocemente sulla strada</b>
                                        </li>
                                    </ul>

                                </li>
                            </ul>
                            <p> </p>
                        </div>
                    </div>
                    <div id="food">
                        <div className="description">
                            <h3>Cibo</h3>
                            <p>Il popolo lettone, maggiormente, mangia carne e pesce affummicato accompagnati con insalate molto ricche, come ad esempio l'insalata russa o patate cucinate in svariati modi e tanta verdura.
                                <br/>
                                <br/>
                                Tra i piatti preferiti, ci sono vari tipi di zuppe, come ad esempio la zuppa con l'acetosa (pianta) oppure la zuppa di crauti.
                                <br/>
                                <br/>
                                Non mancano i dolci: manna bubert, miscuglio di ricotta dolce con vaniglia e uvetta, zuppa di pane con frutta secca e panna montata, crema di ricotta con fiocchi d'avena tostati con zucchero e tanti altri.
                                <br/>
                                Non dimenticate di fare una scorta di buon cioccolato, di vario tipo, che si può trovare tranquillamente nei supermercati (Maxima o Rimi).
                                </p>
                            <p>Per i <b>ristoranti</b>, consigliamo:</p>
                            <ul>
                                <li>
                                    <b>Lido</b>, che si trova sia al centro di Riga che fuori
                                </li>
                                <li>
                                    Ristoranti vari di <b>Sushi</b> (non c'è la formula all you can eat) per la buona qualità del pesce, specie per il salmone (<a target={"_blank"} href="https://www.tripadvisor.it/Restaurants-g274967-c38-Riga_Riga_Region.html">sito</a>)
                                </li>
                                <li>
                                    <b>Taverna</b>
                                </li>
                                <li>
                                    <b>AKA Riga</b>
                                </li>
                                <li>
                                    <b>Key to Riga</b>
                                </li>
                                <li>
                                    <b>Folkklubs ALA</b>
                                </li>
                                <li>
                                    <b>Rozengrāls</b>
                                </li>
                                <li>
                                    <b>Salve</b>
                                </li>
                                <li>
                                    <b>Alus arsenāls</b>
                                </li>
                                <li>
                                    <b>MILDA</b>
                                </li>
                                <li>
                                    <b>Valmiermuižas alus vēstniecība Rīgā</b>
                                </li>
                                <li>
                                    <b>1221</b>
                                </li>
                                <li>
                                    <b>Vecmeita ar kaķi</b>
                                </li>
                                <li>
                                    <b>Citi laiki</b>
                                </li>

                            </ul>
                            <p>Per chi non è abiutato ad un cibo internazionale, consigliamo di fare una cura con fermenti lattici prima di partire &#128540;</p>
                        </div>
                    </div>
                </div>

            </section >
        </>
    )
}

export default Tips;