import "./Home.scss";
import HeroImage from "./HeroImage";
import WeddingDay from "./WeddingDays";
import Form from "./Form";
import ForGift from "./ForGift";
import SaveToDate from "./SaveToDate";
import Footer from "../Footer";

const Home = () => {

  return (
    <>
      <HeroImage />
      <SaveToDate />
      <WeddingDay />
      {/* <Form /> */}
      <ForGift />
      <Footer />
    </>
  )
};

export default Home;