import './ForGift.scss';

import image_card from '../../assets/images/png/card_iban.png'
import image_rotate from '../../assets/images/png/rotate.png'

const ForGift = () => {

    const onCopyHandler = () => {
        var copyText = document.getElementById("myInput");
        copyText.select();
        copyText.setSelectionRange(0, 99999);
        navigator.clipboard.writeText(copyText.value);
    }

    return (
        <section id='WeddingList'>
            {/* <div className='title-seciont-box text-center d-none'>
                <h2>
                    <span className='lv_lv'>Domas mums</span>
                    <span className='it_it'>Un pensiero per noi</span>
                </h2>
            </div>
            <div className='description it_it'>
                <p>Quello che conta veramente è festeggiare insieme a chi ama la nostra unione.</p>
                <p>La destinazione del nostro viaggio di nozze però è molto lontana: <br />se volete, potete aiutarci a raggiungerla.</p>
            </div>
            <div className='description lv_lv'>
                <p>Mums ir ļoti svarīgi svinēt kopā ar cilvēkiem, kas patiesi priecājas par mūsu savienību.</p>
                <p>Lai atvieglotu viesu rūpes par dāvanām, varam pačukstēt, ka tās priecāsimies saņemt aploksnītēs vai caur iban kā atbalstu mūsu medusmēnesim.</p>
            </div>
            <div className='card'>
                <div className='card__side card__side--front '>

                    <img className='rotate' src={image_rotate} />
                </div>
                <div className='card__side card__side--back'>
                    <img src={image_card} />
                    <input className='d-none' type="text" defaultValue="IT66V0760115000001044182697" id="myInput" />
                    <button className='btn btn-outline-primary' onClick={onCopyHandler}>
                        <span className='lv_lv'>Kopēt IBAN numuru</span>
                        <span className='it_it'>Copia numero IBAN</span>
                    </button>
                </div>
            </div> */}
        </section>
    )
}

export default ForGift;