import { useEffect, useState } from 'react';
import './SaveToDate.scss';

const SaveToDate = () => {

    const [isActive, setIsActive] = useState('')

    window.addEventListener('scroll', (e) => {
        let windowPageY = window.pageYOffset;
        let saveToDate = document.getElementById('saveToDate') !== null && document.getElementById('saveToDate').offsetTop - 30;
        if (windowPageY >= saveToDate) {
            setIsActive("active")
        } else {
            setIsActive("")
        }
    });


    return (
        <section id="saveToDate" className={isActive}>

            <div className='save-date-box-parent'>
                <div className='title-seciont-box text-center'>
                    <h2>
                        <span className='lv_lv'>Rezervē datumu</span>
                        <span className='it_it'>Salva la data</span>
                    </h2>
                </div>
                <div className='save-date-box'>
                    <div className='save-date-box--child day-box'>
                        <span className='day'>03</span>
                    </div>
                    <div className='save-date-box--child month-box'>

                        <span className='month'>02</span>
                    </div>
                    <div className='save-date-box--child year-box'>
                        <span className='year'>23</span>
                    </div>
                </div>
                <p>
                    <span className='lv_lv'>Mēs precamies!</span>
                    <span className='it_it'>Ci sposiamo!</span>
                </p>
            </div>
        </section>
    )
}

export default SaveToDate;