import "./Propose.scss";
import video from "../../assets/video/propose.mp4";
import { useEffect, useState } from "react";


import image16 from '../../assets/images/jpg/propose/fot_16.jpg';
import image15 from '../../assets/images/jpg/propose/fot_15.jpg';
import image14 from '../../assets/images/jpg/propose/fot_14.jpg';
import image13 from '../../assets/images/jpg/propose/fot_13.jpg';
import image12 from '../../assets/images/jpg/propose/fot_12.jpg';
import image11 from '../../assets/images/jpg/propose/fot_11.jpg';
import image9 from '../../assets/images/jpg/propose/fot_9.jpg';
import image8 from '../../assets/images/jpg/propose/fot_8.jpg';
import image7 from '../../assets/images/jpg/propose/fot_7.jpg';
import image6 from '../../assets/images/jpg/propose/fot_6.jpg';
import image5 from '../../assets/images/jpg/propose/fot_5.jpg';
import image4 from '../../assets/images/jpg/propose/fot_4.jpg';
import image3 from '../../assets/images/jpg/propose/fot_3.jpg';
import image2 from '../../assets/images/jpg/propose/fot_2.jpg';
import image1 from '../../assets/images/jpg/propose/fot_1.jpg';
import { createPortal } from "react-dom";
import Modal from "../Modal";

const Propose = () => {
  const [isPlay, setIsPlay] = useState(false)
  const [isModal, setIsModal] = useState({ isOpen: false, image: '' })

  const portalElement = document.getElementById('modalBox')

  const dataImage = [
    image1,
    image2,
    image3,
    image4,
    image5,
    image6,
    image7,
    image8,
    image9,
    image11,
    image12,
    image13,
    image14,
    image15,
    image16,
  ]
  // var vid;

  // const onClickButtonVideoHandler = () => {
  //   vid = document.getElementById("videoPropose");
  //   if (vid.paused) {
  //     vid.play();
  //     setIsPlay(true)
  //   } else {
  //     vid.pause();
  //     setIsPlay(false)
  //   }
  // }

  const onZoomHandler = (photo) => {
    setIsModal({ isOpen: true, image: photo })
  }

  return (
    <>
      {createPortal(<Modal isModal={isModal} onClose={() => setIsModal({ isOpen: false, image: '' })} />, portalElement)}
      <section id="propose" className={`${isPlay ? 'play' : ''}`}>
        <div className="title-seciont-box">
          <h2>
            <span className="lv_lv">2021. gada 31. jūlija Bildinājums</span>
            <span className="it_it">La proposta del 31 Luglio 2021</span>
          </h2>
        </div>
        <div className="description-box">
          <p>
            <span className="lv_lv">
              "Bildinājums bija pārdomāts līdz sīkākajām detaļām, pateicoties lieliskajai Baibai (Kintijas māsai), kura man palīdzēja attālināti (grūts darbs), jo bildinājums tika plānots Latvijā.<br />

              Par šīs dienas panākumiem esmu pateicīgs, galvenokārt, viņai, protams, nenoniecinot savu skaisto ideju un līdzdalību (jā, es ar to lepojos! :D). Lai visu sagatavotu, pagāja trīs mēneši, arī tāpēc, ka pandēmijas dēļ nebija viegli, jo Kintija gandrīz katru dienu bija mājās. Es joprojām atceros to gredzenu, kuram nebija viegla dzīve. Īpaši dienā, kad tētis man teica: <b>"Kāpēc tu mazliet neatbrīvo savu skapi no ziemas virsjakām (Romā), tās tagad ir nederīgas (bija praktiski vasara) ..."</b>, tas bija neiespējami! Gredzens atradās manas ziemas jakas kabatā (tētis, protams, tā arī nesaprata, kāpēc es neatbrīvoju skapi no ziemas virsjakām).
              <br /><br />

              Bet tagad pievērsīsimies liktenīgajai dienai, kas bija Kintijas dzimšanas diena (labāku dienu es nevarēju izvēlēties!).<br />
              Man bija ne visai laba pašsajūta starp drudzi un saaukstēšanos (Latvijā to nevar izlaist, pieradu jau pie tā!), bet pāri visam –satraukums par šo.<br />
              Kintija nezināja, kas viņu sagaida! Patiesībā viņa zināja tikai to, ka māsas bija sarūpējušas pārsteigumu. <br />
              Kintija visu rītu pavadīja kopā ar Tolpežņikovu māsām, braucienā no Rīgas līdz Ernesta (Kintijas brāļa) mājām, kas atrodas tieši blakus bildinājuma vietai, ar dažādām pieturām, spēlēm un atrakcijām. Brauciena noslēgumā bija bildinājums:"
            </span>
            <span className="it_it">
              "La proposta è stata pensata fin nei minimi dettagli grazie ad una grandissima complice, Baiba, la quale mi ha aiutato da remoto (arduo lavoro) preché prevista in Lettonia.
              <br />
              Il successo di quanto accaduto lo devo sopratutto a lei, non tralasciando la mia bellissima idea (si, me ne vanto! :D).
              Ci sono voluti ben 3 mesi per preparare il tutto, anche perché non è stato facile avendo Kinti quasi ogni giorno a casa a causa della pandemia.
              Ricordo ancora quell'anello che non ha avuto vita facile, in particolare quando mio padre mi diceva <b>"Perchè non liberi un po' l'armadio (a Roma), ad esempio quei giubbotti invernali, sono inutili ora (era praticamente Estate)..."</b>,
              ciò era impossibile! L'annello si trovava proprio in una tasca del mio giubbotto invernale (mio padre non riusciva a capire il motivo per cui non velossi spostare nulla, ovviamente :D).
              <br />
              <br />
              Ma arriviamo al fatidico giorno, ovvero il compleanno di Kinti (non potevo scegliere giorno migliore).<br />
              Io venivo da un periodo non bellissimo tra febbre e raffreddore (non possono mancare in Lettonia, mi ci sono abiutato) ma soprattutto ANSIA per l'evento. <br />
              Kinti non sapeva cosa le aspettava! Anzi, sapeva solo che c'era una sorpresa organizzata dalle sorelle. <br />
              Infatti, Kinti passò la mattinata con le sorelle facendo vari giri per la Lettonia a tappe con tanti giochi. Dopodichè accadeva questo:

              "
            </span>
          </p>

        </div>
        <div className="video-box">
          <video id="videoPropose" controls>
            <source src={video} type="video/mp4" />
          </video>
          {/* <button onClick={onClickButtonVideoHandler}>
            <svg className="play" viewBox="0 0 15 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M13.4096 10.5309C14.2668 11.3227 14.2668 12.6773 13.4096 13.4691L3.3571 22.7552C2.07614 23.9386 7.2583e-07 23.03 8.02056e-07 21.2861L1.61388e-06 2.71386C1.6901e-06 0.969992 2.07614 0.0614513 3.3571 1.24475L13.4096 10.5309Z" fill="#D9D9D9" />
            </svg>
            <svg className="pause" viewBox="0 0 13 22" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect width="5" height="22" rx="2.5" fill="#D9D9D9" />
              <rect x="8" width="5" height="22" rx="2.5" fill="#D9D9D9" />
            </svg>
          </button> */}
        </div>
        <div className="photo-box">
          {dataImage.map((photo, idx) =>
            <div key={idx} className={`photo-box-child${idx === 0 ? ' first' : ''}${idx === dataImage.length - 2 ? ' penultimate' : ''}${idx === 1 ? ' second' : ''}${idx === 2 ? ' third' : ''}`}
              onClick={() => onZoomHandler(photo)}
            >
              <img src={photo} />
            </div>
          )}

        </div>
      </section>
    </>
  )
};

export default Propose;