import './CentreHistorical.scss';

import image1_canal from '../../assets/images/jpg/riga/canal/1.jpg';
import image2_canal from '../../assets/images/jpg/riga/canal/2.jpg';
import image3_canal from '../../assets/images/jpg/riga/canal/3.jpg';

const Canal = () => {
    return (
        <div className='section__child centre-historical'>
            <div className='box-image image-1'>
                <img src={image1_canal} />
            </div>
            <h3>
                <span className='it_it'>Il canale di Riga</span>
            </h3>
            <div>
                <div className='box-image image-2'>
                    <img src={image2_canal} />
                </div>
                <p>Il <b>canale di Riga</b>, che circonda l’area della città vecchia e ne segna il confine con quella nuova ed immerso in un bel boschetto verde, ricco di aiuole e alberi frondosi, è reso pittoresco da un paio di ponticelli, denominati Ponti dell’Amore (su uno di essi sono stati agganciati gli ormai tipici lucchetti) e da un molo di barche su cui si possono fare brevi gite.
                </p>
                <p>
                    La nascita del canale risale al 1865. Prima di quella data la zona era un terrapieno difensivo incolto. Delle vecchie costruzioni difensive ora rimane solo la Collina del Bastione, dai cui 20 metri di altezza si può comunque godere di una suggestiva vista sul canale e sul parco da un lato e sulla Torre delle Polveri (che oggi ospita il Museo della Guerra) le Caserme di San Giacomo (oggi sede di boutique, caffè e negozi) dall’altro. Date un’occhiata anche all’appariscente facciata dipinta con gli stemmi delle città e delle contee della Lettonia: è uno dei tanti modi in cui a Riga si celebra con fierezza l’indipendenza dello stato lettone.<br/><br/>
                    Dalla collina si vede in lontananza anche il <b>Monumento alla Libertà</b>, il monumento nazionale lettone, all’inizio di Brivibas buvaris, su un’ampia piazza pedonale che spezza in due il canale: alto 42 metri, con in cima ha la statua della Milva, fu inaugurato il 18 novembre 1935 per il 15° anniversario dell’indipendenza della Lettonia. Davanti al monumento la Guardia d’Onore viene cambiata ogni ora.
                </p>
            </div>
            <div>
                <div className='box-image image-3'>
                    <img src={image3_canal} />
                </div>
                <p>Superata la grande piazza pedonale con il monumento, il canale e il parco proseguono nell’area su cui spicca il grande <b>edificio in stile neoclassico dell’Opera Nazionale Lettone</b>, costruita negli anni sessanta: la facciata ha sei colonne ioniche che sostengono un grande portico, il cui timpano è adornato con una scultura di Apollo. Di fronte all’Opera c’è anche una bella fontana con una scultura in bronzo. Decisamente un bel posto dove fare una passeggiata.</p>
            </div>
        </div>
    )
}

export default Canal;