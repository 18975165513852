import image2 from '../../assets/images/jpg/our_history/2018/2.jpg'
import image3 from '../../assets/images/jpg/our_history/2018/3.jpg'
import image4 from '../../assets/images/jpg/our_history/2018/4.jpg'
import image5 from '../../assets/images/jpg/our_history/2018/5.jpg'
import image6 from '../../assets/images/jpg/our_history/2018/6.jpg'
import image7 from '../../assets/images/jpg/our_history/2018/7.jpg'
import image8 from '../../assets/images/jpg/our_history/2018/8.jpg'
import image9 from '../../assets/images/jpg/our_history/2018/9.jpg'
import image10 from '../../assets/images/jpg/our_history/2018/10.jpg'
import image11 from '../../assets/images/jpg/our_history/2018/11.jpg'
import image12 from '../../assets/images/jpg/our_history/2018/12.jpg'
import image13 from '../../assets/images/jpg/our_history/2018/13.jpg'
import image14 from '../../assets/images/jpg/our_history/2018/14.jpg'
import image15 from '../../assets/images/jpg/our_history/2018/15.jpg'
import image16 from '../../assets/images/jpg/our_history/2018/16.jpg'
import image17 from '../../assets/images/jpg/our_history/2018/17.jpg'
import image18 from '../../assets/images/jpg/our_history/2018/18.jpg'
import image19 from '../../assets/images/jpg/our_history/2018/19.jpg'
import image20 from '../../assets/images/jpg/our_history/2018/20.jpg'
import image21 from '../../assets/images/jpg/our_history/2018/21.jpg'
import image22 from '../../assets/images/jpg/our_history/2018/22.jpg'
import image23 from '../../assets/images/jpg/our_history/2018/23.jpg'
import image24 from '../../assets/images/jpg/our_history/2018/24.jpg'
import image25 from '../../assets/images/jpg/our_history/2018/25.jpg'
import image26 from '../../assets/images/jpg/our_history/2018/26.jpg'
import image27 from '../../assets/images/jpg/our_history/2018/27.jpg'
import image28 from '../../assets/images/jpg/our_history/2018/28.jpg'
import image29 from '../../assets/images/jpg/our_history/2018/29.jpg'
import image30 from '../../assets/images/jpg/our_history/2018/30.jpg'
import image31 from '../../assets/images/jpg/our_history/2018/31.jpg'
import image32 from '../../assets/images/jpg/our_history/2018/32.jpg'
import image33 from '../../assets/images/jpg/our_history/2018/33.jpg'
import image34 from '../../assets/images/jpg/our_history/2018/34.jpg'
import image35 from '../../assets/images/jpg/our_history/2018/35.jpg'
import image36 from '../../assets/images/jpg/our_history/2018/36.jpg'
import image37 from '../../assets/images/jpg/our_history/2018/37.jpg'
import image38 from '../../assets/images/jpg/our_history/2018/38.jpg'
import image39 from '../../assets/images/jpg/our_history/2018/39.jpg'
import video from '../../assets/video/video.mp4'

const _2018 = () => {

    return (
        <div className="photo-box__child">
            <img src={image2} />
            <img src={image3} />
            <img src={image4} />
            <img src={image5} />
            <img src={image6} />
            <img src={image7} />
            <img src={image8} />
            <img src={image9} />
            <img src={image10} />
            <img src={image11} />
            <img src={image12} />
            <img src={image13} />
            <img src={image14} />
            <img src={image15} />
            <img src={image16} />
            <img src={image17} />
            <img src={image18} />
            <img src={image19} />
            <img src={image20} />
            <img src={image21} />
            <img src={image22} />
            <img src={image23} />
            <img src={image24} />
            <img src={image25} />
            <img src={image26} />
            <img src={image27} />
            <img src={image28} />
            <img src={image29} />
            <img src={image30} />
            <img src={image31} />
            <img src={image32} />
            <img src={image33} />
            <img src={image34} />
            <img src={image35} />
            <img src={image36} />
            <img src={image37} />
            <img src={image38} />
            {/* <img src={image39} /> */}
            <div className="video-box">
                <video id="videoPropose" controls>
                    <source src={video} type="video/mp4" />
                </video>
            </div>
        </div>
    )
}

export default _2018;