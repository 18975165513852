import { Fragment } from "react";
import HeaderInvitation from "./HeaderInvitation";
import MainInvitation from "./MainInvitation";

const Invitation = () => {
    return (
        <Fragment>
            <HeaderInvitation />
            <MainInvitation />
        </Fragment>
    )
};

export default Invitation;