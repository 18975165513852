import { Fragment } from 'react';
import { useCallback, useEffect, useState } from 'react';
import './Participants.scss';
import { createPortal } from "react-dom";
import Spinner from "../../Spinner";

const Participants = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const [users, setUsers] = useState([])

    const portalElement = document.getElementById('spinnerBox')
    const fetchFormHandler = useCallback(async () => {
        setIsLoading(true);
        setError(null);
        try {
            const response = await fetch(window.location.origin + '/data.json');
            if (!response.ok) {
                throw new Error('Something went wrong!');
            }

            let data = await response.json();

            data = data.form

            let _loadedUser = [];
            for (const key in data) {
                _loadedUser.push({
                    email: data[key].email,
                    firstName: data[key].firstName,
                    lastName: data[key].lastName,
                    note: data[key].note,
                    toChurch: data[key].toChurch,
                    toLocation: data[key].toLocation,
                    family: data[key].family,
                    bus: data[key].bus,
                    sleep: data[key].sleep,
                    brunch: data[key].brunch,
                    allergy: data[key].allergy,
                    vegetarian: data[key].vegetarian,
                    arriving: data[key].arriving,
                    leaving: data[key].leaving,
                    date: data[key].date
                })
            }
            setUsers(_loadedUser)
        } catch (error) {
            setError(error.message);
        }
        setIsLoading(false);
    }, []);

    const [toLocation, setToLocation] = useState(0)
    const [toChurch, setToChurch] = useState(0)
    const [isChildSmall, setIsChildSmall] = useState(0)
    const [isChildBig, setIsChildBig] = useState(0)
    const [adults, setAdults] = useState(0)
    const [sleep, setSleep] = useState(0)
    const [bus, setBus] = useState(0)
    const [brunch, setBrunch] = useState(0)

    const addToLocationHandler = (users, type) => {
        const initValue = 0;
        switch (type) {
            case "location":
                return users.reduce((prevValue, currValue) =>
                    prevValue + (currValue.toLocation === "yes" ? 1 : 0) + parseInt(currValue.family?.length > 0 ? currValue.family.length : 0),
                    initValue)
            case "church":
                return users.reduce((prevValue, currValue) =>
                    prevValue + (currValue.toChurch === "yes" ? 1 : 0) + parseInt(currValue.family?.length > 0 ? currValue.family.length : 0),
                    initValue)
            case "childSmall":
                return users.reduce((prevValue, currValue) =>
                    prevValue + parseInt(currValue.family?.length > 0 ? currValue.family?.filter(_f => _f.age <= 7).length : 0),
                    initValue)
            case "childBig":
                return users.reduce((prevValue, currValue) =>
                    prevValue + parseInt(currValue.family?.length > 0 ? currValue.family?.filter(_f => _f.age > 7 && _f.age <= 15).length : 0),
                    initValue)
            case "adults":
                return users.reduce((prevValue, currValue) =>
                    prevValue + parseInt(currValue.family?.length > 0 ? currValue.family?.filter(_f => _f.age > 15).length : 0) + 1,
                    initValue)
            case "sleep":
                return users.reduce((prevValue, currValue) =>
                    prevValue + (currValue.sleep === "yes" ? 1 : 0) + parseInt(currValue.sleep === "yes" && currValue.family?.length > 0 ? currValue.family.length : 0),
                    initValue)
            case "bus":
                return users.reduce((prevValue, currValue) =>
                    prevValue + (currValue.bus === "yes" ? 1 : 0) + parseInt(currValue.bus === "yes" && currValue.family?.length > 0 ? currValue.family.length : 0),
                    initValue)
            case "brunch":
                return users.reduce((prevValue, currValue) =>
                    prevValue + (currValue.brunch === "yes" ? 1 : 0) + parseInt(currValue.brunch === "yes" && currValue.family?.length > 0 ? currValue.family.length : 0),
                    initValue)
        }
    }

    useEffect(() => {
        if (users) {
            setToLocation(addToLocationHandler(users, 'location'))
            setToChurch(addToLocationHandler(users, 'church'))
            setIsChildSmall(addToLocationHandler(users, 'childSmall'))
            setIsChildBig(addToLocationHandler(users, 'childBig'))
            setAdults(addToLocationHandler(users, 'adults'))
            setSleep(addToLocationHandler(users, 'sleep'))
            setBus(addToLocationHandler(users, 'bus'))
            setBrunch(addToLocationHandler(users, 'brunch'))
        }
    }, [users])



    useEffect(() => {
        fetchFormHandler();
    }, [fetchFormHandler])

    const [show, setShow] = useState(false)

    const toggleShowHandler = () => {
        setShow(!show)
    }

    return (
        <>

            {isLoading && createPortal(<Spinner isLoading={isLoading} />, portalElement)}
            <div className='title-seciont-box text-center'>
                <h2>
                    <span className='lv_lv'>Dalībnieki</span>
                    <span className='it_it'>Partecipanti</span>
                </h2>
            </div>
            <div className='table-responsive'>
                <table>
                    <thead>
                        <tr>
                            <th>Nome</th>
                            <th>Cognome</th>
                            <th>E-mail</th>
                            <th>Note</th>
                            <th>Partecipazione Chiesa</th>
                            <th>Partecipazione Location</th>
                            <th>Prendere bus</th>
                            <th>Dormire alla Location</th>
                            <th>Partecipazione brunch</th>
                            <th>Allergie/Intolleranze</th>
                            <th>Vegatariano/Vegano</th>
                            <th>Arrivo in Lettonia</th>
                            <th>Partenza dalla Lettonia</th>
                            <th>Data inserimento</th>
                        </tr>
                    </thead>
                    <tbody>
                        {error ? <tr><td colSpan={14}>"ERRORE NELLA CHIAMATA": {error}</td></tr> :
                            users.map((user, idx) =>
                                <Fragment key={idx}>
                                    <tr className={`parent ${idx % 2 === 0 ? 'even' : 'odd'}`}>
                                        <td>{user.firstName}</td>
                                        <td>{user.lastName}</td>
                                        <td>{user.email}</td>
                                        <td>{user.note}</td>
                                        <td>{user.toChurch}</td>
                                        <td>{user.toLocation}</td>
                                        {/* <td>{user.family}</td> */}
                                        <td>{user.bus}</td>
                                        <td>{user.sleep}</td>
                                        <td>{user.brunch}</td>
                                        <td>{user.allergy}</td>
                                        <td>{user.vegetarian}</td>
                                        <td>{user.arriving}</td>
                                        <td>{user.leaving}</td>
                                        <td>{user.date}</td>
                                    </tr>
                                    {user.family?.length > 0 &&
                                        <>
                                            <tr className='thead-child border-bottom'>
                                                <td colSpan={4}>Parenti</td>
                                            </tr>
                                            <tr className='thead-child'>
                                                <td>Nome</td>
                                                <td>Cognome</td>
                                                <td>Età</td>
                                                <td>Siederà vicino ai genitori?</td>
                                            </tr>
                                        </>
                                    }
                                    {user.family?.length > 0 && user.family.map((f, idx_f) =>
                                        <tr key={idx_f} className={`child ${idx_f % 2 === 0 ? 'even' : 'odd'}`}>
                                            <td>{f.firstName}</td>
                                            <td>{f.lastName}</td>
                                            <td>{f.age}</td>
                                            <td>{f.table}</td>
                                        </tr>
                                    )}
                                </Fragment>
                            )}
                    </tbody>
                </table>
            </div>
            <div className={`risultati ${show ? 'show' : ""}`}
                style={{
                    top: show ? `calc(100% - ${document.getElementById('infoBox').clientHeight}px - 30px)` : `100%`
                }}>
                <button className="btn" onClick={toggleShowHandler}>
                    {show ?
                        <span>X</span> :
                        <span>i</span>
                    }
                </button>
                <div id="infoBox" className='info-box'>
                    <p>Persone che verranno alla location: <b>{toLocation}</b></p>
                    <p>Persone che verranno alla chiesa: <b>{toChurch}</b></p>
                    <p>Adulti dai 15 anni: <b>{adults}</b></p>
                    <p>Bambini fino a 7 anni: <b>{isChildSmall}</b></p>
                    <p>Bambini da 8 a 15 anni: <b>{isChildBig}</b></p>
                    <p>Persone che dormiranno nella location: <b>{sleep}</b></p>
                    <p>Persone che prenderanno il bus: <b>{bus}</b></p>
                    <p>Persone che faranno colazione/brunch: <b>{brunch}</b></p>
                </div>
            </div>
        </>
    )
}

export default Participants;