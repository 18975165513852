import { useEffect, useState } from 'react';
import './MenuRiga.scss';

const MenuRiga = (props) => {

    const [isDisabledPrev, setisDisabledPrev] = useState(false)
    const [isDisabledNext, setisDisabledNext] = useState(false)

    const onClickActiveButtonHandler = (e) => {
        props.setIsActive(e.currentTarget.getAttribute('data-button'))
    }

    const carouselHandler = (e) => {

        let count = parseInt(props.isActive);
        if (e.currentTarget.getAttribute('class') === "button-prev") {
            setisDisabledNext(false)
            if (count === 4) {
                props.setIsActive('3')
            }
            if (count === 3) {
                props.setIsActive('2')
                setisDisabledPrev(true)
            }
            if (count === 2) {
                props.setIsActive('1')
            }
            if (count === 1) {
                props.setIsActive('1')
            }
        }
        if (e.currentTarget.getAttribute('class') === "button-next") {
            setisDisabledPrev(false)
            if (count === 1) {
                props.setIsActive('2')
            }
            if (count === 2) {
                props.setIsActive('3')
            }
            if (count === 3) {
                props.setIsActive('4')
                setisDisabledNext(true)
            }
            if (count === 4) {
                props.setIsActive('4')
            }
        }
    }

    useEffect(() => {
        setisDisabledPrev(props.isActive === '1' ? true : false)
        setisDisabledNext(props.isActive === '4' ? true : false)
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
    }, [isDisabledPrev, isDisabledNext, props.isActive])


    return (
        <nav id='menuRiga'>
            <h3 className='d-none'>Menu</h3>
            <button className='button-prev' disabled={isDisabledPrev} onClick={(e) => carouselHandler(e)}></button>
            <ul>
                <li className={`btn link ${props.isActive === "1" ? 'active' : ''}`}>
                    <button className={`btn link`} data-button="1" onClick={(e) => onClickActiveButtonHandler(e)}>Il centro storico</button>
                </li>
                <li className={`btn link ${props.isActive === "2" ? 'active' : ''}`}>
                    <button className={`btn link`} data-button="2" onClick={(e) => onClickActiveButtonHandler(e)}>Il canale di Riga</button>
                </li>
                <li className={`btn link ${props.isActive === "3" ? 'active' : ''}`}>
                    <button className={`btn link`} data-button="3" onClick={(e) => onClickActiveButtonHandler(e)}>Art Noveau</button>
                </li>
                <li className={`btn link ${props.isActive === "4" ? 'active' : ''}`}>
                    <button className={`btn link`} data-button="4" onClick={(e) => onClickActiveButtonHandler(e)}>Quartiere Moscovita</button>
                </li>
            </ul>
            <button className='button-next' disabled={isDisabledNext} onClick={(e) => carouselHandler(e)}></button>
        </nav>
    )
}

export default MenuRiga;