import { useCallback, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import Spinner from "../../Spinner";
import "./YourWishes.scss";

const YourWishes = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const [openAlert, setOpenAlert] = useState(false)

    const [users, setUsers] = useState([])

    const portalElement = document.getElementById('spinnerBox')

    const fetchFormHandler = useCallback(async () => {
        setIsLoading(true);
        setError(null);
        try {
            //const response = await fetch('https://swapi.dev/api/films/');
            const response = await fetch(window.location.origin + '/data.json');
            if (!response.ok) {
                throw new Error('Something went wrong!');
            }

            let data = await response.json();

            data = data.formForComments;

            let _loadedUser = [];
            for (const key in data) {
                _loadedUser.push({
                    email: data[key].email,
                    firstName: data[key].firstName,
                    lastName: data[key].lastName,
                    note: data[key].note,
                    date: data[key].date
                })
            }
            setUsers(_loadedUser)

        } catch (error) {
            setError(error.message);
        }
        setIsLoading(false);
    }, []);

    useEffect(() => {
        fetchFormHandler();
    }, [fetchFormHandler])

    const openAlertHandler = () => {
        setOpenAlert(!openAlert)
    }
    return (
        <>
            {isLoading && createPortal(<Spinner isLoading={isLoading} />, portalElement)}
            <section id="yourWishes">
                <div className="title-seciont-box">
                    <h2>
                        <span className="lv_lv">Jūsu novēlējums</span>
                        <span className="it_it">I vostri auguri</span>
                    </h2>
                </div>
                <div className="card-parent">
                    {users.length === 0 &&
                        <div className={`card-child`}>
                            <div className="card kinti-dario">
                                <div className="description">
                                    <h3>

                                        <span className="lv_lv">Kintija un Dario</span>
                                        <span className="it_it">Kintija e Dario</span>
                                    </h3>
                                    <span className="date">
                                        03/02/2023
                                    </span>
                                    <div className="note">
                                        <span className="it_it">Se volete lasciare un vostro pensiero o augurio, basterà cliccare sul bottone in basso a sinistra e compilare la form</span>
                                        <span className="lv_lv">Ja vēlaties atstāt novēlējumu, vienkārši noklikšķiniet uz pogas kreisajā apakšējā stūrī un aizpildiet veidlapu</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {users.map((user, idx) =>
                        <div key={idx} className={`card-child`}>
                            {/* <div key={idx} className="card" style={{ backgroundImage: `url(https://source.unsplash.com/random/500x500?sig=${idx})` }}> */}
                            <div className="card">
                                <div className="description">
                                    <h3>
                                        {user.firstName + ' ' + user.lastName}
                                    </h3>
                                    <span className="date">
                                        {user.date}
                                    </span>
                                    <div className="note">
                                        {user.note}
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {/* <div className={`alert-box ${openAlert ? 'open' : ''}`}
                    style={{
                        top: openAlert ? `calc(100% - ${document.getElementById('alertForUpload').clientHeight}px)` : `100%`
                    }}
                >
                    <button className="btn for-upload" onClick={openAlertHandler}>
                        {openAlert ?
                            <span>X</span> :
                            <span>i</span>
                        }
                    </button>
                    <p id="alertForUpload" className={`alert alert-warning`}>
                        <span className="lv_lv">Ja vēlaties, lai būtu redzama Jūsu fotogrāfija zem novēlējuma, aicinām nosūtīt to mums uz whatsap vai e-pastu.</span>
                        <span className="it_it">Se volete applicare una vostra foto sotto al vostro augurio, inviatecela su whatsapp o per e-mail! </span>
                    </p>
                </div> */}
            </section>
        </>
    )
}

export default YourWishes;