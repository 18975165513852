import { useEffect } from "react";
import FormForComments from "./FormForComments";
import "./Modal.scss";

const Modal = (props) => {
  const closeHandler = (e) => {
    props.onClose();
  }

  const getIframeIt = () => {
    return {
      __html: props.isModal.getApiIt
    }
  }
  const getIframeLv = () => {
    return {
      __html: props.isModal.getApiLv
    }
  }

  useEffect(() => {
    if (props.isModal.isOpen) {
      document.body.classList.add('overflow-hidden')
    } else {
      document.body.classList.remove('overflow-hidden')
    }

    if (props.isModal?.isForm || props.isModal?.detailsSteamBath) {
      document.body.classList.add('isForm')
    } else {
      document.body.classList.remove('isForm')
    }
  }, [props.isModal])

  return (
    <div className={`modal${props.isModal?.isOpen && props.isModal?.isOpen ? ' show' : ''} ${props.isModal?.isForm || props.isModal?.detailsSteamBath ? 'is-form' : ''}`} >
      <div className="modal-header">
        {props.isModal?.isForm &&
          <h3>
            <span className="lv_lv">Uzraksti novēlējumu!</span>
            <span className="it_it">Lascia un augurio!</span>
          </h3>
        }

        {props.isModal?.detailsSteamBath &&
          <h3>
            <span className="lv_lv">Informācija par pirti</span>
            <span className="it_it">Infomrazioni sul Bagno Turco</span>
          </h3>
        }

        <button className="close-button"
          onClick={closeHandler}
        >
          <div>
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>
      </div>
      <div className={`modal-body`}>
        {props.isModal?.image &&
          <div className="image-box">
            <img src={props.isModal.image} />
          </div>
        }
        {props.isModal?.getApiIt &&
          <div className="frame-box it_it">
            <div dangerouslySetInnerHTML={getIframeIt()} />
          </div>
        }
        {props.isModal?.getApiLv &&
          <div className="frame-box lv_lv">
            <div dangerouslySetInnerHTML={getIframeLv()} />
          </div>
        }
        {props.isModal?.isForm &&
          <FormForComments isForm={props.isModal?.isForm} />
        }

        {props.isModal?.detailsSteamBath &&
          <>
            <div className="details lv_lv">
              {/* <p>Il Bagno Turco può ospitare 10 persone alla volta oppure, per privacy, si gestirà a coppia o a famiglia. </p> */}
              <p>Uz pirti iesakām ņemt līdzi peldkostīmu vai arī varat iet pirtī "kā mammas jūs darinājušas"!

                Dvieļus varat ņemt līdzi tos, kas ir istabās.

                Latvijā, tāpat kā citās Ziemeļvalstīs, ir ierasts, ka pēc apmēram 15/20 minūšu ilgas turku peldes burtiski ienirst sniegā.
                Pēc tam jūs atgriezīsieties vēl uz 10/15 minūtēm turku pirtī. Šis "karstā-aukstā-karstā" process neveic neko citu, kā tikai aktivizē ķermeņa asinsriti.
</p>
            </div>
            <div className="details it_it">
              {/* <p>Il Bagno Turco può ospitare 10 persone alla volta oppure, per privacy, si gestirà a coppia o a famiglia. </p> */}
              <p>Per il bagno turco vi consigliamo di portare il costume oppure, se volete, potete restare <i>"come le vostre mamme vi hanno fatto"</i>! </p>
              <p>Per gli asciugamani, potete prendere quelli che avrete nelle stanze.</p>
              <p>In Lettonia c'è un'usanza, come anche in altri paesi nordici, che dopo aver fatto circa 15/20 min di Bagno Turco, ci si tuffa letteralmente nella neve.<br />
                Dopodichè si rientra per altri 10/15 min nel Bagno Turco. Questo processo <i>"caldo-freddo-caldo"</i> non fa altro che riattivare la circolazione del corpo.
              </p>
            </div>
          </>

        }
      </div>
      <div className="modal-footer">
      </div>
    </div >
  )
}
export default Modal;