import { useEffect, useState } from "react";
import { Route, Link, Redirect, Routes, Outlet, useParams, useLocation } from 'react-router-dom';

import './LanguageButtons.scss';


const LanguageButtons = (props) => {


    const activeLanguageHandler = (id) => {
        // let _array = [];
        // _array.push(...props.dataLanguage);
        // for (let item of _array) {
        //     if (props.idUrl === id && item.isActive === true)
        //         return;
        //     item.isActive = item.id_lang === id && !item.isActive
        // }
        // props.setDataLanguage(_array)
        // console.log(props.dataLanguage)
    };

    return (
        <>
            {!props.notVisible && <div className="language-box">
                {
                    props.dataLanguage.map(item => (
                        <Link
                            to={"?lang=" + item.id_lang}
                            className={
                                (props.language === item.id_lang ? 'active' : '')
                            }
                            key={item.id_lang}
                            onClick={() => activeLanguageHandler(item.id_lang)}
                            id={item.id_lang}>
                            {item.name_lang}
                        </Link>
                    ))
                }
            </div>}
        </>

    )
};

export default LanguageButtons;