import { useEffect } from 'react';
import logo from './assets/images/png/logo.png';
import './Spinner.scss';

const Spinner = (props) => {

    useEffect(() => {
        if (!props.isLoading) {
            document.body.classList.add('overflow-hidden')
        } else {
            document.body.classList.remove('overflow-hidden')
        }
    }, [])


    return (
        <div className='spinner'>
            <img src={logo} />
            <div className='letter'>
                <span className='__k'>K</span>
                <span className='__d'>D</span>
            </div>
        </div>
    )
}

export default Spinner;