import './HeaderInvitation.scss';


const HeaderInvitation = () => {
    return (
        <div id='headerInvitation'>
            <div className='hero-image-box'>
            <span className='lv_lv'>Rīga </span>
            <span className='it_it'>Riga</span>
            </div>
        </div>
    )
};

export default HeaderInvitation;