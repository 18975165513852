import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Menu.scss";

const Menu = (props) => {

  const [isOpen, setIsOpen] = useState(false)

  if (isOpen) {
    document.body.classList.add('overflow-hidden', 'isOpenMenu');
  } else {
    document.body.classList.remove('overflow-hidden', 'isOpenMenu');
  }

  const isOpenHandler = () => {
    setIsOpen(!isOpen)
  }

  const toLinkHanlder = () => {
    setIsOpen(false)
    window.scrollTo({
      top: 0,
      behavior: 'auto'
  })
  }

  useEffect(() => {
    if (isOpen)
      props.setNotVisible(false)
    else if (props.location.pathname === '/riga' || props.location.pathname === '/tips')
      props.setNotVisible(true)
  }, [isOpen])


  return (
    <header className={`${isOpen ? "show" : ""}`}>
      <button className="menu-hamburger-button" onClick={isOpenHandler}>
        <div>
          <span></span>
          <span></span>
          <span></span>
        </div>
      </button>
      <div className={`menu-hamburger`}>
        <ul>
          <li>
            <Link onClick={toLinkHanlder} to={`/?lang=${props.language}`}>Home</Link>
          </li>
          <li>
            <Link onClick={toLinkHanlder} to={`/invitation?lang=${props.language}`}>
              <span className="lv_lv">Ielūgums</span>
              <span className="it_it">Invito</span>
            </Link>
          </li>
          <li>
            <Link onClick={toLinkHanlder} to={`/propose?lang=${props.language}`}>
              <span className="lv_lv">Bildinājums</span>
              <span className="it_it">La proposta</span>
            </Link>
          </li>
          <li>
            <Link onClick={toLinkHanlder} to={`/yourwishes?lang=${props.language}`}>
              <span className="lv_lv">Jūsu novēlējumi</span>
              <span className="it_it">I vostri auguri</span>
            </Link>
          </li>
          <li>
            <Link onClick={toLinkHanlder} to={`/ourhistory?lang=${props.language}`}>
              <span className="lv_lv">Mūsu stāsts</span>
              <span className="it_it">La nostra storia</span>
            </Link>
          </li>
          <li className="it_it">
            <Link onClick={toLinkHanlder} to={`/riga?lang=${props.language}`}>
              <span className="it_it">La capitale</span>
            </Link>
          </li>
          <li className="it_it">
            <Link onClick={toLinkHanlder} to={`/tips?lang=${props.language}`}>
              <span className="it_it">Consigli</span>
            </Link>
          </li>
          <li>
            <Link onClick={toLinkHanlder} to={`/menufood?lang=${props.language}`}>
              <span className="lv_lv">Kāzu ēdienkarte</span>
              <span className="it_it">Menu del matrimonio</span>
            </Link>
          </li>
          {/* <li>
            <Link onClick={toLinkHanlder} to="/contacts">
              <span className="lv_lv">Kontakti</span>
              <span className="it_it">Contatti</span>
            </Link>
          </li> */}
        </ul>
      </div>
    </header>
  )
}

export default Menu;