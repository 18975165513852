import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import Modal from '../Modal';
import './WeddingDays.scss';

const WeddingDay = (props) => {
    const [isActive, setIsActive] = useState('first')
    const [isModal, setIsModal] = useState({ isOpen: false, getApiIt: '', getApiLv: '', detailsSteamBath: false })

    const portalElement = document.getElementById('modalBox')

    const onActiveHandler = (e) => {
        if (e.target.getAttribute("data-tab") === "first")
            setIsActive('first')
        else
            setIsActive('second')
    }




    const onZoomHandler = () => {
        setIsModal({
            isOpen: true,
            getApiIt: `<iframe src="https://www.google.com/maps/embed?pb=!1m26!1m12!1m3!1d277752.1491547876!2d24.33824217516089!3d57.05258828399488!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m11!3e6!4m5!1s0x46eecfda0420d1c1%3A0xd524d87e6ef5825f!2sKlostera%20iela%2013%2C%20Centra%20rajons%2C%20R%C4%ABga%2C%20LV-1050%2C%20Lettonia!3m2!1d56.9511008!2d24.1034456!4m3!3m2!1d57.143003699999994!2d25.1380883!5e0!3m2!1sit!2sit!4v1661017735830!5m2!1sit!2sit" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`,
            getApiLv: `<iframe src="https://www.google.com/maps/embed?pb=!1m26!1m12!1m3!1d555523.9194543305!2d24.05802604180016!3d57.0512766667855!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!4m11!3e6!4m5!1s0x46eecfda0420d1c1%3A0xd524d87e6ef5825f!2sKlostera%20iela%2013%2C%20Centra%20rajons%2C%20R%C4%ABga%2C%20LV-1050%2C%20Lettonia!3m2!1d56.9511008!2d24.1034456!4m3!3m2!1d57.143003699999994!2d25.1380883!5e0!3m2!1slv!2sit!4v1661017888904!5m2!1slv!2sit" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>`
        })
    }

    const openModalDetailsHandler = () => {
        setIsModal({ isOpen: true, detailsSteamBath: true })
    }

    return (
        <>
            {createPortal(
                <Modal isModal={isModal}
                    onClose={() => setIsModal({ isOpen: false, getApi: '', detailsSteamBath: false })} />
                , portalElement)}
            <section id="weddingDay">
                <div className='title-seciont-box text-center'>
                    <h2>
                        <span className='lv_lv'>Kāzu dienas</span>
                        <span className='it_it'>I giorni del Matrimonio</span>
                    </h2>
                </div>
                <div className={`tab-menu${isActive === "first" ? ' first' : ' second'}`}>
                    <button onClick={onActiveHandler} data-tab="first" className={`link${isActive === "first" ? ' active' : ''}`}>03/02/2023</button>
                    <button onClick={onActiveHandler} data-tab="second" className={`link${isActive === "second" ? ' active' : ''}`}>04/02/2023</button>
                </div>
                <div className={`tab-box-parent tab-box-parent-${isActive}`}>
                    <div className={`tab-box first ${isActive === "first" ? "show" : ''}`}>
                        <div className='desciption-box'>
                            <h3>
                                <span className='lv_lv'>
                                    Ceremonija
                                </span>
                                <span className='it_it'>
                                    Cerimonia
                                </span>
                            </h3>
                            <b>
                                <span className='lv_lv'>
                                    Plkst. 13:00
                                </span>
                                <span className='it_it'>Orario: 13:00 </span>
                            </b>
                            <p>
                                <span className='lv_lv'>
                                    Ceremonija norisināsies Vecrīgā, Svētās Marijas Magdalēnas katoļu baznīcā.<br />Pēc ceremonijas būs nelielas uzkodas ēkā pretī baznīcai.
                                </span>
                                <span className='it_it'>
                                    La cerimonia si terrà nella Chiesa Cattolica di Santa Maria Maddalena a Riga Vecchia.<br /> Dopo la cerimonia seguirà un breve aperitivo nell'edificio di fronte alla chiesa.
                                </span>
                            </p>
                            <h3>
                                <span className='lv_lv'>
                                    No baznīcas līdz viesu namam
                                </span>
                                <span className='it_it'>
                                    Dalla Chiesa alla Location
                                </span>
                            </h3>
                            <b>
                                <span className='lv_lv'>
                                    Plkst. 14:00/14:30 (vēl jāprecizē)
                                </span>
                                <span className='it_it'>
                                    Orario: 14:00/14:30 (da definire)
                                </span>
                            </b>
                            <p>
                                <span className='lv_lv'>
                                    Jums tiks norādīts, kur ir autobuss, kas aizvedīs līdz Springšļu dzirnavām (brauciena ilgums: 1:30 h).
                                </span>
                                <span className='it_it'>
                                    Sarete indirizzati verso il pullman (successivamente vi indicheremo la zona), che vi accompagnerà alla Location, a Springšļu Dzirnavas (durata della tratta: 1:30 h).
                                </span>
                            </p>

                            <h3>
                                <span className='lv_lv'>
                                    Svinības
                                </span>
                                <span className='it_it'>
                                    Ricevimento
                                </span>
                            </h3>
                            <b>
                                <span className='lv_lv'>
                                    Plkst. 16:00 (vēl jāprecizē)
                                </span>
                                <span className='it_it'>
                                    Orario: 16:00 (da definire)
                                </span>
                            </b>
                            <p>
                                <span className='lv_lv'>
                                    Ierašanās svinību vietā un iekārtošanās... līgavas un līgavaiņa sagaidīšana
                                </span>
                                <span className='it_it'>
                                    Arrivo alla location e sistemazione...in attesa degli sposi
                                </span>
                            </p>
                        </div>
                        <div className='row-image'>
                            <div className='col-image'>
                                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/40/%D0%A6%D0%B5%D1%80%D0%BA%D0%BE%D0%B2%D1%8C_%D0%9C%D0%B0%D1%80%D0%B8%D0%B8_%D0%9C%D0%B0%D0%B3%D0%B4%D0%B0%D0%BB%D0%B8%D0%BD%D1%8B_%28%D0%A0%D0%B8%D0%B3%D0%B0%29_-_4.JPG/250px-%D0%A6%D0%B5%D1%80%D0%BA%D0%BE%D0%B2%D1%8C_%D0%9C%D0%B0%D1%80%D0%B8%D0%B8_%D0%9C%D0%B0%D0%B3%D0%B4%D0%B0%D0%BB%D0%B8%D0%BD%D1%8B_%28%D0%A0%D0%B8%D0%B3%D0%B0%29_-_4.JPG" />
                                <img src="https://www.riga.lv/sites/riga/files/styles/node_image_large/public/gallery_images/baznicas-iekstelpa.jpeg?itok=xcQ88Eov" />
                            </div>
                            <div className='col-image'>
                                <img src="https://www.redzet.eu/images/large/7/14/R-Klostera2-01-14.jpg" />
                            </div>
                        </div>
                    </div>
                    <div className={`tab-box second ${isActive === "second" ? "show" : ''}`}>

                        <div className='row-image'>
                            <div className='col-image'>
                                <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/4/40/%D0%A6%D0%B5%D1%80%D0%BA%D0%BE%D0%B2%D1%8C_%D0%9C%D0%B0%D1%80%D0%B8%D0%B8_%D0%9C%D0%B0%D0%B3%D0%B4%D0%B0%D0%BB%D0%B8%D0%BD%D1%8B_%28%D0%A0%D0%B8%D0%B3%D0%B0%29_-_4.JPG/250px-%D0%A6%D0%B5%D1%80%D0%BA%D0%BE%D0%B2%D1%8C_%D0%9C%D0%B0%D1%80%D0%B8%D0%B8_%D0%9C%D0%B0%D0%B3%D0%B4%D0%B0%D0%BB%D0%B8%D0%BD%D1%8B_%28%D0%A0%D0%B8%D0%B3%D0%B0%29_-_4.JPG" />
                                <img src="https://www.riga.lv/sites/riga/files/styles/node_image_large/public/gallery_images/baznicas-iekstelpa.jpeg?itok=xcQ88Eov" />
                            </div>
                            <div className='col-image'>
                                <img src="https://www.redzet.eu/images/large/7/14/R-Klostera2-01-14.jpg" />
                            </div>
                        </div>
                        <div className='desciption-box'>
                            <h3>
                                <span className='lv_lv'>
                                    Svinības
                                </span>
                                <span className='it_it'>
                                    Ricevimento
                                </span>
                            </h3>
                            <b>
                                <span className='lv_lv'>
                                    Plkst. 09:00 - 11:00 (vēl jāprecizē)
                                </span>
                                <span className='it_it'>
                                    Orario: 09:00 - 11:00 (da definire)
                                </span>
                            </b>
                            <p>
                                <span className='lv_lv'>
                                    Brokastis/brunch.
                                </span>
                                <span className='it_it'>
                                    Colazione/brunch.
                                </span>
                            </p>
                            <b>
                                <span className='lv_lv'>
                                    Plkst. 09:00 (vēl jāprecizē)
                                </span>
                                <span className='it_it'>
                                    Orario: 09:00 (da definire)
                                </span>
                            </b>
                            <p>
                                <span className='lv_lv'>
                                    No rīta viesu nama lejasstāvā būs pieejama arī pirts.
                                </span>
                                <span className='it_it'>
                                    Nella location potrete godere per tutta la mattina di un rilassante bagno turco.
                                </span>
                                {/* <button className='btn btn-outline-primary border-radius-circle only-icon' onClick={openModalDetailsHandler}>i</button> */}
                            </p>
                            <h3>
                                <span className='lv_lv'>
                                    Beigas/sākums..
                                </span>
                                <span className='it_it'>
                                    Fine/Inizio...
                                </span>
                            </h3>
                            <b>
                                <span className='lv_lv'>
                                    Plkst. 15:00 (vēl jāprecizē)
                                </span>
                                <span className='it_it'>
                                    Orario: 15:00 (da definire)
                                </span>
                            </b>
                            <p>
                                <span className='lv_lv'>
                                    Autobuss uz Rīgu.
                                </span>
                                <span className='it_it'>
                                    Vi attenderà il pullman con destinazione Riga.
                                </span>
                            </p>
                            <b>
                                <span className='lv_lv'>
                                    Plkst. 16:00 (vēl jāprecizē)
                                </span>
                                <span className='it_it'>
                                    Orario: 16:00 (da definire)
                                </span>
                            </b>
                            <p>
                                <span className='lv_lv'>
                                    Svinību beigas, bet ne līgavai un līgavainim...
                                </span>
                                <span className='it_it'>
                                    Fine dei festeggiamenti, ma non per gli sposi...
                                </span>
                            </p>
                        </div>
                    </div>
                </div>
                <div className='destination-box text-center'>
                    <button className='btn btn-primary' onClick={onZoomHandler}>
                        <span className='it_it'>Apri percorso dalla Chiesa alla Location</span>
                        <span className='lv_lv'>Atvērt maršrutu no baznīcas līdz viesu namam</span>
                    </button>
                </div>
            </section>
        </>
    )
}

export default WeddingDay;