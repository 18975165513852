import { useCallback, useEffect, useState } from "react";
import './FormForComments.scss';

const FormForComments = (props) => {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        note: '',
        date: new Date().toLocaleDateString()
    })
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);

    const [status200, setStatus200] = useState(false);

    const [user, setUser] = useState([])

    const [isExistEmail, setIsExistEmail] = useState("")
    const [isExistFirstName, setIsExistFirstName] = useState("")
    const [isExistLastName, setIsExistLastName] = useState("")
    const [isExistUser, setIsExistUser] = useState([])

    const [isEmpty, setIsEmpty] = useState(false)
    const [isValidateExistEmail, setIsValidateExistEmail] = useState(false)
    const [isValidateEmail, setIsValidateEmail] = useState(false)
    const [isValidateFirsLastName, setIsValidateFirsLastName] = useState(false)

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const fetchFormHandler = useCallback(async () => {
        setIsLoading(true);
        setError(null);
        try {
            //const response = await fetch('https://swapi.dev/api/films/');
            const response = await fetch('https://wedding-d5b46-default-rtdb.firebaseio.com/formForComments.json');
            if (!response.ok) {
                throw new Error('Something went wrong!');
            }

            const data = await response.json();


            setUser([data])
            let _arrayEmail = [];
            let _arrayFirstName = [];
            let _arrayLastName = [];
            let _arrayUser = []
            for (const key in data) {
                _arrayEmail.push(data[key].email)
                _arrayFirstName.push(data[key].firstName)
                _arrayLastName.push(data[key].lastName)
                _arrayUser.push({ firstName: data[key].firstName, lastName: data[key].lastName })
            }
            setIsExistEmail(_arrayEmail)
            setIsExistFirstName(_arrayFirstName)
            setIsExistLastName(_arrayLastName)
            setIsExistUser(_arrayUser)
            // const transformedMovies = data.map((movieData) => {
            //   return {
            //     id: movieData.episode_id,
            //     title: movieData.title,
            //     openingText: movieData.opening_crawl,
            //     releaseDate: movieData.release_date,
            //   };
            // });
            // setMovies(loadedMovies);
        } catch (error) {
            setError(error.message);
        }
        setIsLoading(false);
    }, []);

    async function addFormHandler() {
        const response = await fetch('https://wedding-d5b46-default-rtdb.firebaseio.com/formForComments.json', {
            method: 'POST',
            body: JSON.stringify(formData),
            headers: {
                'Content-Type': 'application/json'
            }
        });
        const data = await response.json();
        if (response.ok) {
            setStatus200(true)
            setTimeout(() => {
                window.location.reload();
            }, 1000);
        }
    }

    useEffect(() => {

        if (isExistUser.length > 0) {
            for (const el of isExistUser) {
                if (formData.firstName === "" && formData.lastName === "") { setIsValidateFirsLastName(true); break }
                if (formData.firstName.toLowerCase().trim() === el.firstName.toLowerCase().trim() &&
                    formData.lastName.toLowerCase().trim() === el.lastName.toLowerCase().trim()) {
                    setIsValidateFirsLastName(false);
                    break;
                } else {
                    setIsValidateFirsLastName(true);
                }
            }
        } else {
            setIsValidateFirsLastName(true);
        }

        if (formData.email !== "" &&
            !String(formData.email)
                .toLowerCase()
                .match(
                    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                )) {
            setIsValidateEmail(false)
        } else {
            setIsValidateEmail(true)
        }

        if (formData.email !== "" && isExistEmail.includes(formData.email)) {
            setIsValidateExistEmail(false)
        }
        else {
            setIsValidateExistEmail(true)
        }
        if (formData.firstName === "" || formData.lastName === "" || formData.email === "" || formData.note === "") {
            setIsEmpty(false)
        } else {
            setIsEmpty(true)
        }
    }, [formData, isExistUser])

    useEffect(() => {
        fetchFormHandler();
    }, [fetchFormHandler])

    return (
        <div className='form for-comments'>
            <fieldset className='legend'>
                <b>
                    <span className='it_it'>* Obbligatorio</span>
                    <span className='lv_lv'>* Obligāti</span>
                </b>
            </fieldset>
            <fieldset>
                <div className='form-group'>
                    <label htmlFor='firstname' className='d-block mb-10 required'>
                        <span className='lv_lv'>Vārds</span>
                        <span className='it_it'>Nome</span>
                    </label>
                    <input type="text" id='firstname' name='firstName' value={formData.firstName} onChange={handleInputChange} />
                </div>
                <div className='form-group'>
                    <label htmlFor='lastname' className='d-block mb-10 required'>
                        <span className='lv_lv'>Uzvārds</span>
                        <span className='it_it'>Cognome</span>
                    </label>
                    <input required type="text" id='lastname' name='lastName' value={formData.lastName} onChange={handleInputChange} />
                </div>
                <div className='form-group email-box w-100'>
                    <label htmlFor='email' className='d-block mb-10 required'>
                        <span className='lv_lv'>E-pasts</span>
                        <span className='it_it'>E-mail</span>
                    </label>
                    <input required type="email" id='email' name='email' value={formData.email} onChange={handleInputChange} />
                </div>
                <div className='form-group w-100'>
                    <label htmlFor='note' className='d-block mb-10 '>
                        <span className='lv_lv'>Atstāj komentāru*</span>
                        <span className='it_it'>Lascia un commento*</span>
                    </label>
                    <textarea type="text" rows="4" id='note' value={formData.note} name='note' onChange={handleInputChange} />
                </div>
            </fieldset>
            <fieldset className='for-alert'>
                {!isValidateFirsLastName && formData.firstName !== "" &&
                    <div className='alert alert-warning'>
                        <span className="lv_lv">Jau esošais vārds un uzvārds</span>
                        <span className="it_it">Nome e Cognome già presenti</span>
                    </div>}
                {!isValidateExistEmail &&
                    <div className='alert alert-warning'>
                        <span className="lv_lv">Šis e-pasts jau pastāv</span>
                        <span className="it_it">Questa e-mail già è presente</span>
                    </div>
                }
                {!isValidateEmail &&
                    <div className='alert alert-warning'>
                        <span className="lv_lv">Nepareizs --- {formData.email} --- e-pasts</span>
                        <span className="it_it">E-mail --- {formData.email} --- non corretta</span>
                    </div>
                }
                {status200 &&
                    <div className='alert alert-success'>
                        <span className='lv_lv'>Saglabāts veiksmīgi!</span>
                        <span className='it_it'>Salvataggio andato a buon fine!</span>
                    </div>
                }
            </fieldset>
            {isValidateExistEmail && isValidateEmail && isValidateFirsLastName && isEmpty &&
                <div className='action-box'>
                    <button className='btn btn-primary' onClick={addFormHandler}>
                        <span className='lv_lv'>Saglabāt</span>
                        <span className='it_it'>Salva</span>
                    </button>
                </div>
            }
        </div>
    )
}

export default FormForComments;