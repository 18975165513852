import "./HeroImage.scss";
import logo from '../../assets/images/png/logo.png';
import { useEffect, useState } from "react";

const HeroImage = () => {
  const [changeCSS, setChangeCSS] = useState("")

  const [isActive, setIsActive] = useState("")

  setTimeout(() => {
    setChangeCSS("end")
  }, 0.3);

  window.addEventListener('scroll', (e) => {
    let windowPageY = window.pageYOffset;
    let saveToDate = document.getElementById('heroImage') !== null && document.getElementById('heroImage').offsetTop;
    if (windowPageY === saveToDate) {
      setIsActive("active")
    } else {
      setIsActive("")
    }
  });

  return (
    <section id="heroImage" >
      <div className={`name-box ${changeCSS} ${isActive}`}>
        {["K", "I", "N", "T", "I", "J", "A"].map((letter, idx) =>
          <span key={idx} className={"left"} style={{
            left: `${-200 - idx}px`,
            transition: `all 2s ${0.1 * idx}s`
          }}>{letter}</span>
        )}
        <div className="image-box">
          <img src={logo} />
        </div>
        {["D", "A", "R", "I", "O"].map((letter, idx) =>
          <span key={idx} className={"right"} style={{
            right: `${-200 - idx}px`,
            transition: `all 2s ${0.1 * idx}s`
          }}>{letter}</span>
        )}
      </div>
      <div className="hero-image-box">
      </div>
    </section>
  )
};

export default HeroImage;