import React, { useEffect, useState, useRef } from 'react';
import { Route, Link, Redirect, Routes, Navigate, useParams, useNavigate, Outlet, useLocation } from 'react-router-dom';

import LanguageButtons from './compontents/language/LanguageButtons';
import Invitation from './compontents/invitation/Invitation';

import './App.scss';
import Menu from './compontents/menu/Menu';
import Home from './compontents/home/Home';
import Propose from './compontents/propose/Propose';
import LineDate from './compontents/LineDate';
import Header from './compontents/Header';
import Participants from './compontents/participants/Participants';
import HomeTest from './compontents/home/HomeTest';
import YourWishes from './compontents/yourWishes/YourWishes';
import ButtonForWishes from './compontents/ButtonForWishes';
import OurHistory from './compontents/ourHistory/OurHistory';
import Riga from './compontents/riga/Riga';
import Tips from './compontents/tips/Tips';

import firebase from 'firebase/compat/app';
import { onAuthStateChanged } from 'firebase/auth'
import { getFirestore } from 'firebase/firestore'


// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import AuthGoogle from './AuthGoogle';
import { Authenticated } from './Authenticated';
import MenuFood from './compontents/MenuFood/MenuFood';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional



const App = () => {
  const [lang, setLang] = useState([
    { id_lang: "lv_lv", name_lang: "LV", isActive: true },
    { id_lang: "it_it", name_lang: "IT", isActive: false }
  ]);
  const [language, setLanguage] = useState("lv_lv")
  const [notVisible, setNotVisible] = useState(false)

  let params = new URLSearchParams(document.location.search);
  let paramsId = params.get("lang"); // is the string "Jonathan"
  const location = useLocation()

  // if (paramsId == null)
  //   window.history.pushState("state", undefined, `#/lang=${lang.find(item => item.isActive === true).id_lang}`);

  useEffect(() => {
    if (location.search.split("=")[1] === "lv_lv") {
      setLanguage("lv_lv")
    }
    if (location.search.split("=")[1] === "it_it") {
      setLanguage("it_it")
    }
    if (language === "lv_lv") {
      document.body.classList.add('lv_lv')
      document.body.classList.remove('it_it')
    }
    if (language === "it_it") {
      document.body.classList.add('it_it')
      document.body.classList.remove('lv_lv')
    }

  }, [location, language])

  const firebaseConfig = {
    apiKey: "AIzaSyAMfBdGWvjwphd01yBD9iFGOznNUrU2ZHM",
    authDomain: "wedding-d5b46.firebaseapp.com",
    databaseURL: "https://wedding-d5b46-default-rtdb.firebaseio.com",
    projectId: "wedding-d5b46",
    storageBucket: "wedding-d5b46.appspot.com",
    messagingSenderId: "1039577850755",
    appId: "1:1039577850755:web:c71a4101bafcdf9f6e8cb6",
    measurementId: "G-MHGSMVGLPX"
  };

  // Initialize Firebase
  const app = firebase.initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);

  const [userAuth, setUserAuth] = useState({})
  const db = getFirestore()
  var userUID;

  // async function addUsers() {
  //   const response = await fetch('https://wedding-d5b46-default-rtdb.firebaseio.com/users.json', {
  //     method: 'POST',
  //     body: JSON.stringify(userAuth),
  //     headers: {
  //       'Content-Type': 'application/json'
  //     }
  //   });
  //   const data = await response.json();

  //   // if (response.ok) {
  //   //   setStatus200(true)
  //   //   setTimeout(() => {
  //   //     window.location.reload();
  //   //   }, 1000);
  //   // }
  // }

  useEffect(() => {
    var userCurrent = firebase.auth();
    onAuthStateChanged(firebase.auth(), (user) => {
      if (user) {
        setUserAuth({ email: user.email, uid: user.uid })
      }
      else {
        setUserAuth({})
      }
    })
   
  }, [])



  return (
    <div className={`App`}>
      {/* <AuthGoogle auth={firebase.auth()} />
      <Authenticated userAuth={userAuth} />
      <button onClick={() => firebase.auth().signOut()}>Sign Out</button> */}
      <LanguageButtons notVisible={notVisible} dataLanguage={lang} language={language} setDataLanguage={setLang} idUrl={paramsId}></LanguageButtons>
      <Menu setNotVisible={setNotVisible} location={location} language={language} />
      {/* <LineDate /> */}
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/invitation' element={<Invitation />} />
        <Route path='/propose' element={<Propose />} />
        <Route path='/participants' element={<Participants />} />
        <Route path='/yourwishes' element={<YourWishes />} />
        <Route path='/ourhistory' element={<OurHistory />} />
        <Route path='/riga' element={<Riga />} />
        <Route path='/tips' element={<Tips />} />
        <Route path='/menufood' element={<MenuFood />} />
      </Routes>
      {/* <ButtonForWishes /> */}
    </div>
  );
}

export default App;
