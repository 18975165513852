import { useEffect, useState } from 'react';
import './OurHistory.scss';

import image1 from '../../assets/images/jpg/propose/IMG_2438.jpeg'

import _2018 from './_2018';
import _2019 from './_2019';
import _2020 from './_2020';
import _2021 from './_2021';
import _2022 from './_2022';

const OurHistory = () => {
    const [nameBox, setNameBox] = useState('')
    const [isActive, setIsActive] = useState('')

    const openBoxPhotoHandler = (e) => {
        setNameBox(e.currentTarget.getAttribute("data-box"))
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        })
        setTimeout(() => {
            setIsActive("")
        }, 1000);
    }
    const closeBoxHandler = () => {
        setNameBox("")
    }

    const onClickForMobileHandler = (e) => {
        setIsActive(e.currentTarget.getAttribute("data-box"))
    }

    useEffect(() => {
        if (window.innerWidth < 1024) {
            if (nameBox !== "")
                document.body.classList.add('overflow-hidden')
            else
                document.body.classList.remove('overflow-hidden')
        } else {
            document.body.classList.remove('overflow-hidden')
        }

        window.addEventListener('resize', () => {
            if (window.innerWidth < 1024) {
                if (nameBox !== "")
                    document.body.classList.add('overflow-hidden')
                else
                    document.body.classList.remove('overflow-hidden')
            } else {
                document.body.classList.remove('overflow-hidden')
            }
        }, true)
    }, [nameBox])


    return (
        <section id='OurHistory'>
            <div className='title-seciont-box text-center'>
                <h2>
                    <span className="lv_lv">Mūsu stāsts</span>
                    <span className="it_it">La nostra Storia</span>
                </h2>
            </div>
            <div className='date-box-parent'>
                <div className='date-box'>
                    <div className={'date-box__child' + (isActive === '2018' ? ' active' : "")} data-box="2018" onClick={(e) => onClickForMobileHandler(e)}>
                        <div className='details'>
                            <h4>
                                <span className='it_it'>2 Ottobre</span>
                                <span className='lv_lv'>2. oktobris</span>
                            </h4>
                            <div className='description'>
                                <p className='lv_lv'>
                                    Mūsu stāsts sākās Instagramā, kur mēs viens otram daudz rakstījām no jūlija līdz septembra beigām.<br /><br />

                                    Kintija atradās Rimini, kur strādāja viesnīcā vasaras sezonas darbu, bet Dario bija Benevento, kur mācījās web designer kursos.<br /><br />

                                    Pirmā tikšanās notika 2. oktobrī Bari, kur uzreiz uzliesmoja dzirkstele.<br /><br />

                                    Pirms Kintija devās uz Latviju, mēs vēl divas reizes tikāmies Vasto.<br /><br />

                                    Dario novembra beigās bija pārcēlies uz Romu, lai strādātu savā pirmajā darbā.<br /><br />

                                    Mūsu pirmais ceļojums bija uz Stokholmu, kur mēs svinējām arī Jauno gadu.<br /><br />


                                </p>
                                <p className='it_it'>
                                    La nostra storia è iniziata su Instagram dove ci scrivevamo davvero tanto tra Luglio e Settembre.<br /><br />
                                    Kinti si trovava a Rimini che faceva la stagione estiva in un albergo, mentre Dario a Benevento, dove stava seguendo un corso di web designer. <br /><br />
                                    Il primo appuntamento è stato a Bari, il 2 Ottobre, dove è scoppiata subito la scintilla. <br /><br />
                                    Ci siamo poi rivisti a Vasto altre due volte prima che Kinti partisse per la Lettonia. <br /><br />
                                    Dario nel frattempo si era trasferito a Roma per il suo primo lavoro <br /><br />
                                    Il nostro primo viaggio è stato a Stoccolma, dove abbiamo festeggiato anche il capodanno.
                                </p>
                            </div>
                            <button className='btn btn-outline-primary' data-box="2018" onClick={(e) => openBoxPhotoHandler(e)}><span className="it_it">Vedi di più</span>
                                <span className="lv_lv">Skatīt vairāk</span></button>
                        </div>
                        <h3>2018</h3>
                    </div>
                    <div className={'date-box__child' + (isActive === '2019' ? ' active' : "")} data-box="2019" onClick={(e) => onClickForMobileHandler(e)}>
                        <div className='details'>
                            <h4>
                                <span className='it_it'>Dario in Lettonia</span>
                                <span className='lv_lv'>Dario Latvijā</span>
                            </h4>
                            <div className='description'>
                                <p className='lv_lv'>
                                    Mūsu stāsts iegāja jaunā līmenī, kad Kintija pārcēlās uz Romu, jo īpaši tad, kad vasaras beigās sākām dzīvot kopā. <br /><br />

                                    Mēs braucām vairākos ceļojumos pa Itāliju, kā piemēram, Perudžiju (Umbrija reģions), Saturniju (Toskānas reģions), Castelli Romani (Lacio reģions), Sperlonga (Lacio reģions), Monte Circeo (Lacio reģions), Roccaraso (Abruco reģions) un daudz kur citur..., kā arī bijām divas reizes Latvijā (pirmā Dario reize Latvijā bija oktobrī). <br /><br />

                                    Rudenī mums bija pirmie viesi - Marija un Kīts. <br /><br />
                                    Mēs piedalījāmies divās kāzās - Dario drauga, Salvatore, kāzās Itālijā un Kintijas māsas, Katrīnas, kāzās Latvijā. <br /><br />
                                    Pateicoties draugam Kristapam, Dario savā dzimšanas dienā izmēģināja “īsto alkoholu”. :D

                                </p>
                                <p className='it_it'>
                                    La nostra storia si è consolidata quando Kinti si è trafserita a Roma, in particolare quando siamo andati a vivere insieme verso la fine dell'estate. <br /><br />
                                    Abbiamo subito organizzato tanti viaggi in Italia, come ad esempio Perugia (Umbria), Saturnia (Toscana), Castelli Romani (Lazio), Sperlonga (Lazio), Monte Circeo (Lazio), Roccaraso (Abruzzo) e tanti altri...,
                                    ma anche due viaggi in Lettonia (i primi per Dario).<br /><br />
                                    In Autunno abbiamo avuto i nostri primi ospiti, Marija e Keith.<br /><br />

                                    Abbiamo partecipato a due matrimoni, uno dell'amico di Dario, Salvatore, in Italia, e uno della sorella di Kinti, Katrina, in Lettonia.<br /><br />
                                    Dario ha provato la vera Vodka in Lettonia al suo compleanno grazie all'amico Kristaps.<br /><br />
                                </p>
                            </div>
                            <button className='btn btn-outline-primary' data-box="2019" onClick={(e) => openBoxPhotoHandler(e)}><span className="it_it">Vedi di più</span>
                                <span className="lv_lv">Skatīt vairāk</span></button>
                        </div>
                        <h3>2019</h3>
                    </div>
                    <div className={'date-box__child' + (isActive === '2020' ? ' active' : "")} data-box="2020" onClick={(e) => onClickForMobileHandler(e)}>
                        <div className='details'>
                            <h4>
                                <span className='it_it'>Isolati e non</span>
                                <span className='lv_lv'>Izolēti un ne</span>
                            </h4>
                            <div className='description'>
                                <p className='lv_lv'>
                                    Mēs noorganizējām braucienu uz Štutgarti, lai tiktos ar Kintijas māsu, Mariju, pirms ieradās bēdīgi slavenais Covid-19.<br /><br />

                                    Mums bija tas gods kļūt par Lūkasa, Marijas un Kīta dēla, krustvecākiem.<br /><br />

                                    Sākotnējais covid periods bija grūts, kā jūs visi zināt. Izolēšanās Romā nebija viegla. Tā kā Kintija nestrādāja (viesnīca bija slēgta covid dēļ), mēs devāmies no Romas uz Benevento pie Dario ģimenes.<br /><br />

                                    Covid-19 ļāva mums atklāt kaut ko tādu, bez kā mēs vairs nevaram iztikt - mātes ieraugu.<br /><br />

                                    Vasarā mēs devāmies uz Salento, Apūlijas reģionā, kur pavadījām brīnišķīgas brīvdienas (Kintijai tā bija pirmā reize Apūlijas dienvidos).<br /><br />

                                    Mēs izbaudījām arī nelielu ceļojumu ar Dario vectēvu,Saverio, bijām uz viņa iemīļoto kalnu Campitello Matese (Molīzes reģionā).

                                </p>
                                <p className='it_it'>
                                    Abbiamo organizzato il viaggio a Stoccarda per incontrare la sorella di Kinti, Marija, prima che arrivasse il famigerato Covid-19.<br /><br />
                                    Abbiamo avuto l'onore di diventare i padrini di Lukass, figlio di Marija e Keith.<br /><br />
                                    Il periodo iniziale del covid è stato difficile, come tutti sapete. Restare chiusi in casa a Roma non era facile.
                                    Visto che Kinti non lavorava (l'albergo era chiuso per covid) siamo andati via dalla città per raggiungere i familiari di Dario a Benevento. <br /><br />
                                    Il covid ci ha permesso, communque, di scoprire una cosa che ad oggi non possiamo più fare a meno, il lievito madre. <br /><br />
                                    In estate siamo stati in Puglia, nel Salento, dove abbiamo trascorso una bellissima vacanza (per Kinti è stata la prima volta nel sud della Puglia)<br /><br />
                                    Abbiamo approfittato anche di un piccolo viaggio con il nonno di Dario sulla sua montagna preferita, Campitello Matese (Molise).


                                </p>
                            </div>
                            <button className='btn btn-outline-primary' data-box="2020" onClick={(e) => openBoxPhotoHandler(e)}><span className="it_it">Vedi di più</span>
                                <span className="lv_lv">Skatīt vairāk</span></button>
                        </div>
                        <h3>2020</h3>
                    </div>
                    <div className={'date-box__child' + (isActive === '2021' ? ' active' : "")} data-box="2021" onClick={(e) => onClickForMobileHandler(e)}>
                        <div className='details'>
                            <h4>
                                <span className='it_it'>Altri viaggi</span>
                                <span className='lv_lv'>Citi izbraucieni</span>
                            </h4>
                            <div className='description'>
                                <p className='lv_lv'>

                                    Šajā gadā mūsu dzīve mainījās, mēs pametām Romu un pārcēlāmies uz Vasto, izvēloties veselīgāku dzīvesveidu.<br /><br />

                                    Netrūka arī citu izbraucienu ar Dario jauno automašīnu.<br /><br />

                                    Aizgājām abi no darba un uz veselu mēnesi aizbraucām uz Latviju.<br /><br />
                                    Milzīgs pārsteigums Kintijai, un ne tikai, bija bildinājums viņas dzimšanas dienā.

                                </p>
                                <p className='it_it'>In questo anno la nostra vita è cambiata, abbiamo lasciato Roma per trasferirci a Vasto, una scelta di vita più sana.<br /><br />
                                    Non sono mancati altri viaggi con la nuova macchina di Dario.<br /><br />
                                    Abbiamo trascorso un mese in Lettonia, dove, al compleanno di Kinti, Dario ha fatto la proposta di matrimonio.

                                </p>
                            </div>
                            <button className='btn btn-outline-primary' data-box="2021" onClick={(e) => openBoxPhotoHandler(e)}><span className="it_it">Vedi di più</span>
                                <span className="lv_lv">Skatīt vairāk</span></button>
                        </div>
                        <h3>2021</h3>
                    </div>
                    <div className={'date-box__child' + (isActive === '2022' ? ' active' : "")} data-box="2022" onClick={(e) => onClickForMobileHandler(e)}>
                        <div className='details'>
                            <h4>
                                <span className='it_it'>I viaggi continuano...</span>
                                <span className='lv_lv'>Ceļojumi turpinās...</span>
                            </h4>
                            <div className='description'>
                                <p className='lv_lv'>
                                    Februārī mēs devāmies uz Latviju, lai izvēlētos kāzu vietu.<br /><br />

                                    Maija beigās mēs bijām Parīzē uz Ilzes un Miguela dēla, Hugo, kristībām/ dzimšanas dienas svinībām (Dario tā bija pirmā reize Parīzē).<br /><br />

                                    Būdami Vasto, mēs apceļojām Abruco reģionu, atklājot dažādas mazas un lielas pilsētas, ciematus.<br /><br />

                                    Dario nosvinēja savu dzimšanas dienu Toskānā, pateicoties Kintijas super organizācijai. Kopā ar mums bija arī Kintijas tētis, Juris.<br /><br />

                                    Novembra beigās Kintiju sagaidīja pārsteigums no māsām un draudzenēm, kuras bija noorganizējušas viņas vecmeitu ballīti Romā.


                                </p>
                                <p className='it_it'>
                                    A febbraio siamo andati in Lettonia per scegliere la location per il matrimonio.<br /><br />
                                    Fine maggio siamo stati a Parigi per il battesimo/ compleanno di Hugo, figlio di Ilze e Miguel (per Dario è stata la prima volta a Parigi).<br /><br />
                                    Trovandoci a Vasto, abbiamo girato l'Abruzzo scoprendo altre piccole e grandi realtà.<br /><br />
                                    Dario ha festeggiato il suo compleanno in Toscana grazie ad una super organizzazione fatta da Kinti. Con noi è stato anche il papà di Kinti, Juris. <br /><br />
                                    A fine novembre Kinti ha avuto una sorpresa dalle sorelle e dalle amiche, le quali hanno organizzato il suo addio al nubilato a Roma.
                                </p>
                            </div>
                            <button className='btn btn-outline-primary' data-box="2022" onClick={(e) => openBoxPhotoHandler(e)}><span className="it_it">Vedi di più</span>
                                <span className="lv_lv">Skatīt vairāk</span></button>
                        </div>
                        <h3>2022</h3>
                    </div>
                </div>
                <div className={`photo-box ${nameBox !== "" ? 'show' : ''}`}>
                    <button className="close-button" onClick={closeBoxHandler}>
                        <div>
                            <span></span>
                            <span></span>
                            <span></span>
                        </div>
                    </button>
                    {nameBox === '2018' &&
                        <_2018 />
                    }
                    {nameBox === '2019' && <_2019 />}
                    {nameBox === '2020' && <_2020 />}
                    {nameBox === '2021' && <_2021 />}
                    {nameBox === '2022' && <_2022 />}
                </div>
            </div>
        </section>
    )
}

export default OurHistory;