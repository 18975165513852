import image1 from '../../assets/images/jpg/our_history/2022/1.jpg'
import image2 from '../../assets/images/jpg/our_history/2022/2.jpg'
import image3 from '../../assets/images/jpg/our_history/2022/3.jpg'
import image4 from '../../assets/images/jpg/our_history/2022/4.jpg'
import image5 from '../../assets/images/jpg/our_history/2022/5.jpg'
import image6 from '../../assets/images/jpg/our_history/2022/6.jpg'
import image7 from '../../assets/images/jpg/our_history/2022/7.jpg'
import image8 from '../../assets/images/jpg/our_history/2022/8.jpg'
import image9 from '../../assets/images/jpg/our_history/2022/9.jpg'
import image10 from '../../assets/images/jpg/our_history/2022/10.jpg'
import image11 from '../../assets/images/jpg/our_history/2022/11.jpg'
import image12 from '../../assets/images/jpg/our_history/2022/12.jpg'
import image13 from '../../assets/images/jpg/our_history/2022/13.jpg'
import image14 from '../../assets/images/jpg/our_history/2022/14.jpg'
import image15 from '../../assets/images/jpg/our_history/2022/15.jpg'
import image16 from '../../assets/images/jpg/our_history/2022/16.jpg'
import image17 from '../../assets/images/jpg/our_history/2022/17.jpg'
import image18 from '../../assets/images/jpg/our_history/2022/18.jpg'
import image19 from '../../assets/images/jpg/our_history/2022/19.jpg'
import image20 from '../../assets/images/jpg/our_history/2022/20.jpg'
import image21 from '../../assets/images/jpg/our_history/2022/21.jpg'
import image22 from '../../assets/images/jpg/our_history/2022/22.jpg'
import image23 from '../../assets/images/jpg/our_history/2022/23.jpg'
import image24 from '../../assets/images/jpg/our_history/2022/24.jpg'
import image25 from '../../assets/images/jpg/our_history/2022/25.jpg'
import image26 from '../../assets/images/jpg/our_history/2022/26.jpg'
import image27 from '../../assets/images/jpg/our_history/2022/27.jpg'
import image28 from '../../assets/images/jpg/our_history/2022/28.jpg'
import image29 from '../../assets/images/jpg/our_history/2022/29.jpg'
import image30 from '../../assets/images/jpg/our_history/2022/30.jpg'
import image31 from '../../assets/images/jpg/our_history/2022/31.jpg'
import image32 from '../../assets/images/jpg/our_history/2022/32.jpg'
import image33 from '../../assets/images/jpg/our_history/2022/33.jpg'
import image34 from '../../assets/images/jpg/our_history/2022/34.jpg'
import image35 from '../../assets/images/jpg/our_history/2022/35.jpg'
import image36 from '../../assets/images/jpg/our_history/2022/36.jpg'
import image37 from '../../assets/images/jpg/our_history/2022/37.jpg'
import image38 from '../../assets/images/jpg/our_history/2022/38.jpg'
import image39 from '../../assets/images/jpg/our_history/2022/39.jpg'
import image40 from '../../assets/images/jpg/our_history/2022/40.jpg'
import image41 from '../../assets/images/jpg/our_history/2022/41.jpg'
import image42 from '../../assets/images/jpg/our_history/2022/42.jpg'
import image43 from '../../assets/images/jpg/our_history/2022/43.jpg'
import image44 from '../../assets/images/jpg/our_history/2022/44.jpg'
import image45 from '../../assets/images/jpg/our_history/2022/45.jpg'
import image46 from '../../assets/images/jpg/our_history/2022/46.jpg'
import image47 from '../../assets/images/jpg/our_history/2022/47.jpg'
import image48 from '../../assets/images/jpg/our_history/2022/48.jpg'
import image49 from '../../assets/images/jpg/our_history/2022/49.jpg'
import image50 from '../../assets/images/jpg/our_history/2022/50.jpg'
import image51 from '../../assets/images/jpg/our_history/2022/51.jpg'
import image52 from '../../assets/images/jpg/our_history/2022/52.jpg'
import image53 from '../../assets/images/jpg/our_history/2022/53.jpg'
import image54 from '../../assets/images/jpg/our_history/2022/54.jpg'
import image55 from '../../assets/images/jpg/our_history/2022/55.jpg'
import image56 from '../../assets/images/jpg/our_history/2022/56.jpg'
import image57 from '../../assets/images/jpg/our_history/2022/57.jpg'
import image58 from '../../assets/images/jpg/our_history/2022/58.jpg'
import image59 from '../../assets/images/jpg/our_history/2022/59.jpg'
import image60 from '../../assets/images/jpg/our_history/2022/60.jpg'
import image61 from '../../assets/images/jpg/our_history/2022/61.jpg'
import image62 from '../../assets/images/jpg/our_history/2022/62.jpg'
import image63 from '../../assets/images/jpg/our_history/2022/63.jpg'
import image64 from '../../assets/images/jpg/our_history/2022/64.jpg'
import image65 from '../../assets/images/jpg/our_history/2022/65.jpg'
import image66 from '../../assets/images/jpg/our_history/2022/66.jpg'
import image67 from '../../assets/images/jpg/our_history/2022/67.jpg'
import image68 from '../../assets/images/jpg/our_history/2022/68.jpg'
import image69 from '../../assets/images/jpg/our_history/2022/69.jpg'
import image70 from '../../assets/images/jpg/our_history/2022/70.jpg'
import image71 from '../../assets/images/jpg/our_history/2022/71.jpg'
import image72 from '../../assets/images/jpg/our_history/2022/72.jpg'
import image73 from '../../assets/images/jpg/our_history/2022/73.jpg'
import image74 from '../../assets/images/jpg/our_history/2022/74.jpg'
import image75 from '../../assets/images/jpg/our_history/2022/75.jpg'
import image76 from '../../assets/images/jpg/our_history/2022/76.jpg'
import image77 from '../../assets/images/jpg/our_history/2022/77.jpg'
import image78 from '../../assets/images/jpg/our_history/2022/78.jpg'
import image79 from '../../assets/images/jpg/our_history/2022/79.jpg'
import image80 from '../../assets/images/jpg/our_history/2022/80.jpg'
import image81 from '../../assets/images/jpg/our_history/2022/81.jpg'
import image82 from '../../assets/images/jpg/our_history/2022/82.jpg'
import image83 from '../../assets/images/jpg/our_history/2022/83.jpg'
import image84 from '../../assets/images/jpg/our_history/2022/84.jpg'
import image85 from '../../assets/images/jpg/our_history/2022/85.jpg'
import image86 from '../../assets/images/jpg/our_history/2022/86.jpg'
import image87 from '../../assets/images/jpg/our_history/2022/87.jpg'
import image88 from '../../assets/images/jpg/our_history/2022/88.jpg'
import image89 from '../../assets/images/jpg/our_history/2022/89.jpeg'
import image91 from '../../assets/images/jpg/our_history/2022/91.jpeg'
import image92 from '../../assets/images/jpg/our_history/2022/92.jpeg'
import image93 from '../../assets/images/jpg/our_history/2022/93.jpeg'
import image94 from '../../assets/images/jpg/our_history/2022/94.jpeg'
import image95 from '../../assets/images/jpg/our_history/2022/95.jpeg'
import image96 from '../../assets/images/jpg/our_history/2022/96.jpeg'

const _2022 = () => {

    return (
        <div className="photo-box__child">
            <img src={image1} />
            <img src={image2} />
            <img src={image3} />
            <img src={image4} />
            <img src={image5} />
            <img src={image6} />
            <img src={image7} />
            <img src={image8} />
            <img src={image9} />
            <img src={image10} />
            <img src={image11} />
            <img src={image12} />
            <img src={image13} />
            <img src={image14} />
            <img src={image15} />
            <img src={image16} />
            <img src={image17} />
            <img src={image18} />
            <img src={image19} />
            <img src={image20} />
            <img src={image21} />
            <img src={image22} />
            <img src={image23} />
            <img src={image24} />
            <img src={image25} />
            <img src={image26} />
            <img src={image27} />
            <img src={image28} />
            <img src={image29} />
            <img src={image30} />
            <img src={image31} />
            <img src={image32} />
            <img src={image33} />
            <img src={image34} />
            <img src={image35} />
            <img src={image36} />
            <img src={image37} />
            <img src={image38} />
            <img src={image39} />
            <img src={image40} />
            <img src={image41} />
            <img src={image42} />
            <img src={image43} />
            <img src={image44} />
            <img src={image45} />
            <img src={image46} />
            <img src={image47} />
            <img src={image48} />
            <img src={image49} />
            <img src={image50} />
            <img src={image51} />
            <img src={image52} />
            <img src={image53} />
            <img src={image54} />
            <img src={image55} />
            <img src={image56} />
            <img src={image57} />
            <img src={image58} />
            <img src={image59} />
            <img src={image60} />
            <img src={image61} />
            <img src={image62} />
            <img src={image63} />
            <img src={image64} />
            <img src={image65} />
            <img src={image66} />
            <img src={image67} />
            <img src={image68} />
            <img src={image69} />
            <img src={image70} />
            <img src={image71} />
            <img src={image72} />
            <img src={image73} />
            <img src={image74} />
            <img src={image75} />
            <img src={image76} />
            <img src={image77} />
            <img src={image78} />
            <img src={image79} />
            <img src={image80} />
            <img src={image81} />
            <img src={image82} />
            <img src={image83} />
            <img src={image84} />
            <img src={image85} />
            <img src={image86} />
            <img src={image87} />
            <img src={image88} />
            <img src={image89} />
            <img src={image91} />
            <img src={image92} />
            <img src={image93} />
            <img src={image94} />
            <img src={image95} />
            <img src={image96} />
        </div>
    )
}

export default _2022;