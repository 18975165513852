import './Footer.scss';

const Footer = () => {

    return (
        <footer>
            <h3>
                <span className='lv_lv'>Kontakti</span>
                <span className='it_it'>Contatti</span>
            </h3>
            <ul>
                <li>
                    <span className='lv_lv'>Topošā līgava un līgavainis</span>
                    <span className='it_it'>I futuri sposi</span>
                    <ul>
                        <li>
                            Dario <a href='tel:+393932512085'>+39 393 25 12 085</a>
                        </li>
                        <li>
                            Kintija <a href='tel:+393881504569'>+39 388 15 04 569</a>
                        </li>
                    </ul>
                </li>
                <li className=''>
                    <span className='lv_lv'>Vedēji</span>
                    <span className='it_it'>I testimoni</span>
                    <ul>
                        <li>
                            Baiba <a href='tel:+37126425350'>+371 26 425 350</a>
                        </li>
                        <li>
                            Arvis <a href='tel:+37129629602'>+371 29 629 602</a>
                        </li>
                    </ul>
                </li>
            </ul>
        </footer>
    )
}

export default Footer;