import React, { Fragment } from "react";

import './GoogleMaps.scss'

const GoogleMaps = (props) => {
   
    const getIframeLv = () => {
        return {
            __html: props.getLv
        }
    }
    const getIframeIt = () => {
        return {
            __html: props.getIt
        }
    }

    return (
        <div>
            <div className="lv_lv" dangerouslySetInnerHTML={getIframeLv()} />
            <div className="it_it" dangerouslySetInnerHTML={getIframeIt()} />
        </div>
    )
};

export default GoogleMaps;