
import { Fragment, useState } from 'react';
import logo from '../../assets/images/png/logo.png';
import image from '../../assets/images/png/image.png';
import GoogleMaps from '../googleMaps/GoogleMaps';
import "./MainInvitation.scss"
import Clock from './contdown/Clock';

const MainInvitation = () => {

    const iframeCeremonyIt = '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2175.881763359068!2d24.101441815878424!3d56.95082698089065!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46eecfda0101ef8d%3A0x6f66db2345ccbdbe!2zUsSrZ2FzIFN2xJN0xIFzIE1hcmlqYXMgTWFnZGFsxJNuYXMgYmF6bsSrY2E!5e0!3m2!1sit!2sit!4v1649617466544!5m2!1sit!2sit" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>';
    const iFrameCeremonyLv = '<iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2175.8817633590693!2d24.101441815977378!3d56.95082698089064!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x46eecfda0101ef8d%3A0x6f66db2345ccbdbe!2zUsSrZ2FzIFN2xJN0xIFzIE1hcmlqYXMgTWFnZGFsxJNuYXMgYmF6bsSrY2E!5e0!3m2!1slv!2sit!4v1649618638032!5m2!1slv!2sit" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>';
    const iframeReceptionIt = '<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d8658.58654310211!2d25.1391123!3d57.1431031!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x84ae09d1bd837899!2zU3ByaW5nxaHEvHUgZHppcm5hdmFz!5e0!3m2!1sit!2sit!4v1650189313824!5m2!1sit!2sit" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>';
    const iFrameReceptionLv = '<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d8658.58654310211!2d25.1391123!3d57.1431031!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x84ae09d1bd837899!2zVmllc27Eq2NhIFNwcmluZ8WhxLx1IGR6aXJuYXZhcw!5e0!3m2!1slv!2sit!4v1650189381152!5m2!1slv!2sit" width="600" height="450" style="border:0;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>';

    const [deadLine, setDeadLine] = useState({ deadline: "Febrary, 3, 2023" })

    return (
        <Fragment>


            <main>
                <div className='main-top'>
                    <div className='avatar'></div>
                    <div className='image-box'>
                        <img src={logo} />
                    </div>
                    <div className='avatar'></div>
                </div>
                {/* <hr /> */}
                <div className='main-middle'>
                    <p>
                        <span className='lv_lv'>...ar prieku paziņojam par mūsu kāzu dienu...</span>
                        <span className='it_it'>...siamo lieti di annunciare il giorno del nostro matrimonio...</span>
                    </p>
                    {/* <div className='date-box'>
                        <div className='day'>03</div>
                        <div className='month'>02</div>
                        <div className='year'>23</div>
                    </div> */}
                    <div className='date-box-change'>
                        <div>
                            <span className='number'>03</span>
                            <span className='it_it'>giorno</span>
                            <span className='lv_lv'>diena</span>
                        </div>
                        <div>
                            <span className='number'>02</span>
                            <span className='it_it'>mese</span>
                            <span className='lv_lv'>mēnesis</span>
                        </div>
                        <div>
                            <span className='number'>23</span>
                            <span className='it_it'>anno</span>
                            <span className='lv_lv'>gads</span>
                        </div>
                    </div>


                    <Clock deadline={deadLine.deadline} />

                    <p className='confirm it_it'>È gradita gentile conferma entro il 31 Ottobre 2022</p>
                    <p className='confirm lv_lv'>Laipni lūdzam apstiprināt ierašanos/neierašanos līdz 2022.gada 31. oktobrim</p>
                </div>

                <div className='main-bottom'>
                    <div className='location-box'>
                        <div>
                            <h2>
                                <span className="lv_lv">Ceremonija</span>
                                <span className="it_it">Cerimonia</span>
                            </h2>
                            <p>
                                <span className="lv_lv">Svētās Marijas Magdalēnas baznīca</span>
                                <span className="it_it">Chiesa Santa Maria Maddalena</span>
                            </p>
                            <p>
                                <span className="lv_lv">Klostera iela 2, Rīga</span>
                                <span className="it_it">Via Klostera 2, Riga</span>
                            </p>
                        </div>
                        <GoogleMaps getIt={iframeCeremonyIt} getLv={iFrameCeremonyLv} />

                    </div>
                    <div className='location-box'>
                        <div>
                            <h2>
                                <span className="lv_lv">Svinības</span>
                                <span className="it_it">Ricevimento</span>
                            </h2>
                            <p>
                                <span className="lv_lv">Springšļu Dzirnavas</span>
                                <span className="it_it">Springšļu Dzirnavas</span>
                            </p>
                            <p>
                                <span className="lv_lv">Līgatnes Novads</span>
                                <span className="it_it">Regione di Līgatne</span>
                            </p>
                        </div>
                        <GoogleMaps getIt={iframeReceptionIt} getLv={iFrameReceptionLv} />
                    </div>

                </div>
                <p className="details-box lv_lv"><span></span>Kāzu detaļas paziņosim vēlāk</p>
                <p className="details-box it_it">I dettagli del matrimonio verranno esposti in seguito</p>
            </main>
        </Fragment>
    )
};

export default MainInvitation;